import {
  reducer,
  UPDATE_ORDER,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  DELIVERY_ASSISTANCE,
  ADD_REVIEW,
  ADD_REVIEW_FAILURE,
  ADD_REVIEW_SUCCESS,
  FETCH_REVIEW,
  FETCH_REVIEW_FAILURE,
  FETCH_REVIEW_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  restaurantOrdersList: [],
  deliveryAssistant: true,
  reviews: {},
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ORDER_SUCCESS:
    case ADD_REVIEW_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_ORDER_FAILURE:
    case ADD_REVIEW_FAILURE:
    case FETCH_REVIEW_FAILURE:
      return { ...state, loading: false };

    case UPDATE_ORDER:
    case ADD_REVIEW:
      return {
        ...state,
        // loading: true,
      };
    case FETCH_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case DELIVERY_ASSISTANCE:
      return { ...state, deliveryAssistant: payload };
      case FETCH_REVIEW_SUCCESS: {
        return {
          ...state,
          reviews: payload,
          loading: false,
        };
      }
    default:
      return state;
  }
};

export const isLoading = (state) => {
  return state[reducer].loading;
};

export const getDeliveryAssistance = (state) =>
  state[reducer].deliveryAssistant;
  export const getReviewsList = (state) => {
    return state[reducer].reviews;
  };
export { reducer };
