import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Drawer
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from 'components/common/switch';
import Address from 'components/common/address';
import { getCurrentTheme } from '../../global/ThemeReducer';
import { toggleTheme } from '../../global/actions';
import { saveCurrentDeliveryAddress } from 'containers/CartPage/actions'
import { getCurrentDeliveryAddress } from 'containers/CartPage/reducer'
import { activeLocation } from "containers/DashBoard/reducer";
import { saveCurrentLocation } from "containers/DashBoard/actions";
import { getServices } from "containers/DashBoard/actions";
import { getRestaurants } from "containers/Restaurants/RestaurantsList/actions";
import { getCarousel } from "containers/DashBoard/actions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar({ openDrawer }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [showAddress, setShowAddress] = useState(false)
  const dispatch = useDispatch()
  const { palette } = useSelector(getCurrentTheme)
  const currentAddress = useSelector(getCurrentDeliveryAddress)
  const currentLocation = useSelector(activeLocation)

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (!currentAddress?.name && !currentLocation?.name) setShowAddress(true);
    else setShowAddress(false)
  }, [currentAddress, currentLocation])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const handleAddress = (data) => {
    setShowAddress(false);
    if (data?.address){
      dispatch(saveCurrentDeliveryAddress(data?.address));
      dispatch(saveCurrentLocation(data?.address?.location));
      if (data?.address?.location?.id !== currentLocation?.id) {
        dispatch(getServices());
        dispatch(getRestaurants({ status: "APPROVED" }));
        dispatch(getCarousel())
      }
  }}

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="relative" color="light">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            style={{ marginRight: 0 }}
            sx={{ mr: 2 }}
            onClick={() => openDrawer()}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="span"
            noWrap
            component="div"
            sx={{ display: { xs: "block", sm: "block" } }}
            onClick={() => setShowAddress(true)}
          >

            <p style={{ margin: 0, display: 'flex' }}><LocationOnIcon />{currentAddress?.location?.id === currentLocation?.id ? currentAddress?.type : currentLocation?.name || currentLocation?.name} <KeyboardArrowDownIcon /></p>
            {currentAddress?.location?.id === currentLocation?.id ? 
            currentAddress?.name &&
              <p
                style={{
                  fontSize: "10px",
                  margin: 0,
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {currentAddress?.name}, {currentAddress?.address1}, {currentAddress?.address2}
              </p>
              :
              <p
              style={{
                fontSize: "10px",
                margin: 0,
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Select Address
            </p>
              }
          </Typography>
          <Switch
            onClick={() => dispatch(toggleTheme(palette.mode === 'light' ? 'dark' : 'light'))}
            style={{ position: 'absolute', zIndex: 999 }} sx={{ m: 1 }}
            checked={palette.mode === 'dark' ? true : false} />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        anchor="top"
        sx={{
          '.MuiPaper-elevation': {
            maxHeight: '60%'
          }
        }}

        open={showAddress}
        transitionDuration={500}
        onClose={() => currentAddress?.name && setShowAddress(!showAddress)}
      >
        <Address deliveryAddress={currentAddress} onSelect={handleAddress} isDashboard  title="Select delivery Address"/>

      </Drawer>
    </Box>
  );
}
