import {
  GET_RESTAURANTS,
  GET_RESTAURANTS_SUCCESS,
  GET_RESTAURANTS_FAILURE,
  SAVE_CURRENT_RESTAURANT,
  TGRIGGER_BACKBTN,
  reducer,
} from './constants';

const initialState = {
  loading: false,
  restaurantList: [],
  currentRestaurant: {},
  backBtn: { key: false, val: [] }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RESTAURANTS:
      return {
        ...state,
        loading: true,
      };
    case GET_RESTAURANTS_FAILURE:
      return { ...state, loading: false };
    case GET_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        restaurantList: payload,
        loading: false,
      };
    }

    case SAVE_CURRENT_RESTAURANT:{
      return { ...state, currentRestaurant: payload };
    }
    case TGRIGGER_BACKBTN:
      return { ...state, backBtn: payload };
    default:
      return state;
  }
};

export const getRestaurantsList = (state) => {
  return state[reducer].restaurantList;
};

export const getCurrentRestaurant = (state) => {
  return state[reducer].currentRestaurant;
};

export const getTriggerBackbtn = (state) => {
  return state[reducer].backBtn;
};
export const isLoading = (state) => {
  return state[reducer].loading;
};
export { reducer };
