export const reducer = 'RestaurantOrders';

export const UPDATE_ORDER = reducer + '/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = reducer + '/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = reducer + '/UPDATE_ORDER_FAILURE';

export const ADD_REVIEW = reducer + '/ADD_REVIEW';
export const ADD_REVIEW_SUCCESS = reducer + '/ADD_REVIEW_SUCCESS';
export const ADD_REVIEW_FAILURE = reducer + '/ADD_REVIEW_FAILURE';

export const FETCH_REVIEW = reducer + '/FETCH_REVIEW';
export const FETCH_REVIEW_SUCCESS = reducer + '/FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAILURE = reducer + '/FETCH_REVIEW_FAILURE';

export const DELIVERY_ASSISTANCE = reducer + '/DELIVERY_ASSISTANCE';