export const reducer = 'UserOrders';

export const GET_USER_ORDERS = reducer + '/GET_USER_ORDERS';
export const GET_USER_ORDERS_SUCCESS = reducer + '/GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = reducer + '/GET_USER_ORDERS_FAILURE';

export const GET_ORDERS_COUNT = reducer + '/GET_ORDERS_COUNT';
export const GET_ORDERS_COUNT_SUCCESS = reducer + '/GET_ORDERS_COUNT_SUCCESS';
export const GET_ORDERS_COUNT_FAILURE = reducer + '/GET_ORDERS_COUNT_FAILURE';

export const UPDATE_ADDRESS = reducer + '/UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = reducer + '/UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = reducer + '/UPDATE_ADDRESS_FAILURE';

export const ADD_ORDER = reducer + '/ADD_ORDER';
export const ADD_ORDER_SUCCESS = reducer + '/ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = reducer + '/ADD_ORDER_FAILURE';

export const ADD_NEW_ADDRESS = reducer + '/ADD_NEW_ADDRESS';
export const ADD_NEW_ADDRESS_SUCCESS = reducer + '/ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_FAILURE = reducer + '/ADD_NEW_ADDRESS_FAILURE';

export const GET_DELIVERY_ADDRESS = reducer + '/GET_DELIVERY_ADDRESS';
export const GET_DELIVERY_ADDRESS_SUCCESS =
  reducer + '/GET_DELIVERY_ADDRESS_SUCCESS';
export const GET_DELIVERY_ADDRESS_FAILURE =
  reducer + '/GET_DELIVERY_ADDRESS_FAILURE';
