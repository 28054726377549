import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { GET, POST, PUT } from 'restClient';
import { GET_USER_ORDERS } from 'containers/Restaurants/UserOrders/constants';
import * as types from './constants';
import { getCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/reducer';
import * as notificationtTypes from 'containers/DashBoard/constants';

function* _update_order({ payload }) {
  try {
    const response = yield call(PUT, {
      url: '/restaurant/update-order',
      requestBody: payload.data,
    });
    if (payload.hotelId) {
      yield put({
        type: GET_USER_ORDERS,
        payload: { hotelId: payload.hotels, history: payload.history },
      });
    }
    yield put({ type: types.UPDATE_ORDER_SUCCESS, payload: response.data });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Order updated', type: 'success' } });

  } catch (error) {
    yield put({ type: types.UPDATE_ORDER_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Order update failed', type: 'error' } });

  }
}


function* _add_review({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/restaurant/add-review',
      requestBody: payload,
    });
    if (payload.written_by) {
      yield put({
        type: GET_USER_ORDERS,
        payload: { userId: payload.written_by },
      });
    }
    yield put({ type: types.ADD_REVIEW_SUCCESS, payload: response.data });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Review added', type: 'success' } });
  } catch (error) {
    yield put({ type: types.ADD_REVIEW_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add review failes', type: 'error' } });

  }
}

function* _fetch_reviews({ payload }) {
  try {
    // const HotelId = yield select(getCurrentRestaurant);
    const response = yield call(GET, {
      url: '/restaurant/fetch-review',
      params: payload,
    });
    yield put({ type: types.FETCH_REVIEW_SUCCESS, payload: response.data.message });
  } catch (error) {
    yield put({ type: types.FETCH_REVIEW_FAILURE, payload: error });
  }
}


const _update_order_watcher = takeEvery(types.UPDATE_ORDER, _update_order);
const _add_review_watcher = takeEvery(types.ADD_REVIEW, _add_review);
const _fetch_reviews_watcher = takeEvery(types.FETCH_REVIEW, _fetch_reviews);

export default function* mainSaga() {
  yield all([_update_order_watcher]);
  yield all([_add_review_watcher]);
  yield all([_fetch_reviews_watcher]);
}
