import React, { memo, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Stack,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Card,
    Paper,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Avatar,
    ImageListItem
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import CopyrightTwoToneIcon from '@mui/icons-material/CopyrightTwoTone';
import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/name.png'
import Carousel from 'components/common/carousel';
import { getCurrentTheme } from '../../global/ThemeReducer';
import Header from './Header';
import SkeletonComp from './skeleton';
import { getCarousel, getLocations } from 'containers/DashBoard/actions';
import { getcarouselList, getservicesList, isLoading } from 'containers/DashBoard/reducer';
import useDispatch from 'components/common/dispatchHook'
import ConfirmModal from 'components/common/confirm-modal'
import { getLoggedInUser } from 'containers/LoginPage/reducer';
import { sideMenu, sideOwnerMenu } from './helper';
import { saveCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/actions';
import { saveDeliveryServiceType } from 'containers/DashBoard/actions';
import BottomNavigationComponent from 'components/common/bottomNavigator'
import LogoutMessage from 'components/common/logoutMessage';
import LogoutIcon from '@mui/icons-material/Logout';
import UpdateMessage from 'components/common/updateMessage';
import Advertising from './advertising';
const drawerWidth = 240;
import currentPackage from '../../../package.json';
import { getVersion } from 'containers/LoginPage/reducer';
import { showNotification } from 'containers/DashBoard/actions';
import { triggerAddItem } from 'containers/Restaurants/RestaurantDishes/actions';
import { getTopRestaurants } from 'containers/DashBoard/actions';
import { getTopRestaurantsList } from 'containers/DashBoard/reducer';
import { handleNativeBackPress } from 'containers/LoginPage/actions';
import { activeLocation } from 'containers/DashBoard/reducer';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default memo(() => {
    const theme = useTheme();
    const router = useNavigate();
    const [dispatchAction, getStateData] = useDispatch();
    const [open, setOpen] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)
    const restaurants = getStateData(getTopRestaurantsList)
    const loading = getStateData(isLoading)
    const carouselImages = getStateData(getcarouselList)
    const loggedInUser = getStateData(getLoggedInUser);
    const services = (getStateData(getservicesList))?.services
    const [openLogoutMessage, setOpenLogoutMessage] = useState(false);
    const currentVersion = getStateData(getVersion);
    const currrentLocation = getStateData(activeLocation);
    const [showGif, setShowGif] = useState(true)

    function handleUpdateCheck(item) {
        if (currentVersion.version !== currentPackage?.version) {
            dispatchAction(showNotification({ message: 'Please update app' }));
            setShowConfirm(true);
        }
        else return true
    }

    function updateApp() {
        // window.location.href = 'https://play.google.com/store/apps/details?id=com.nammuru';
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1)
            window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.nammuru";
        else
            window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.nammuru";
    }


    function handleClose() {
        setOpenLogoutMessage(false);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const { palette } = useSelector(getCurrentTheme)
    React.useEffect(() => {
        if (localStorage.getItem('gif-loaded')){
            setShowGif(false)
        } else {
            setTimeout(() => {
                localStorage.setItem('gif-loaded', 'loaded');
                setShowGif(false);
            }, [5000])
        }
        if (!currrentLocation?.name) dispatchAction(getLocations());
        dispatchAction(getTopRestaurants());
        dispatchAction(getCarousel());
        dispatchAction(triggerAddItem({}));
        dispatchAction(handleNativeBackPress(false));
    }, [])


    React.useEffect(() => {
        handleUpdateCheck()
        loggedInUser?.hotels && dispatchAction(saveCurrentRestaurant(loggedInUser?.hotels[0]))
    }, [loggedInUser])

    return (<>
        <ConfirmModal
            message="Please, Update to proceed"
            title="New Version Available"
            okText="Update App"
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            handleCancel={updateApp}
            handleOk={updateApp}
        />
        {loading ? <SkeletonComp /> : <>
            {showGif && <ImageListItem style={{ position: 'absolute', height: '80%', zIndex: '9999' }} key={"item.img"}>
                <img
                    src={`/splash.gif`}
                    alt={"item.title"}
                    loading="lazy"
                />
            </ImageListItem>}
            <Header openDrawer={handleDrawerOpen} />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                onClose={() => handleDrawerClose()}
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <img src={Logo} style={{ width: 160, height: 50 }} alt="loading..." />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10%',
                        marginTop: '5%'
                    }} disablePadding>
                        <PortraitTwoToneIcon fontSize="large" />
                        <span>{loggedInUser?.name}</span>
                        <span>{loggedInUser?.pno}</span>
                    </ListItem>
                    {loggedInUser?.role === "OWNER" && sideOwnerMenu?.map((text, index) => (
                        <Paper onClick={() => router(text?.path)} key={index} elevation={3} style={{ marginBottom: '1%' }}>
                            <ListItem key={text?.title} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {text?.Icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text?.title} />
                                </ListItemButton>
                            </ListItem>
                        </Paper>
                    ))}
                    {sideMenu?.map((text, index) => (
                        <Paper onClick={() => router(text?.path)} key={index} elevation={3} style={{ marginBottom: '1%' }}>
                            <ListItem key={text?.title} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {text?.Icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text?.title} />
                                </ListItemButton>
                            </ListItem>
                        </Paper>
                    ))}
                </List>
                <Paper onClick={() => setOpenLogoutMessage(true)} elevation={3} style={{ marginBottom: '1%' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItemButton>
                    </ListItem>
                </Paper>
                <Paper elevation={3}>
                    <ListItem style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        bottom: 0
                    }} disablePadding>
                        <span>
                            v - {currentPackage?.version}
                        </span>
                        <span style={{ display: 'flex' }}><CopyrightTwoToneIcon /> codestackr software slns</span>
                    </ListItem>
                </Paper>
            </Drawer>
            <Main open={open}>
                <div style={{ position: 'relative' }}
                    onClick={handleDrawerClose}
                >
                    <div>

                        <Carousel images={carouselImages} services={services} />
                        <Box

                            style={{
                                marginTop: '-20px',
                                backgroundColor: palette.mode === 'light' ? '#F78F35' : '#671a1a',
                                width: '100%',
                                borderTopLeftRadius: 40,
                                borderTopRightRadius: 40,
                                position: 'absolute',
                                paddingTop: '20px',
                                // paddingLeft: '10px',
                                padding: '10px',
                                fontWeight: palette.mode === 'light' ? 550 : ''
                            }}>
                            {/* {palette.mode === 'light' ? <h4 style={{ margin: 0, textAlign: "center" }}>We are in {currrentLocation?.name || "MALUR"}</h4> : <h4 style={{ margin: 0, textAlign: "center" }}>We are in {currrentLocation?.name || "MALUR"}</h4>} */}
                            {palette.mode === 'light' ? <h4 style={{ margin: 0, textAlign: "center", marginBottom: '4px' }}>What Are you looking for today</h4> : <h4 style={{ margin: 0, textAlign: "center" }}>What Are you looking for today</h4>}
                            <Stack direction="row" spacing={2} id="custom-scroll" justifyContent={"center"} style={{ maxWidth: '100%', overflowX: 'scroll' }}>
                                {services &&
                                    services?.map(item =>
                                        <Stack alignItems={"center"} textAlign={"center"}
                                            sx={{ opacity: item?.location_services?.isAvailable ? 1 : 0.5 }}
                                            onClick={() => {
                                                if (!item?.location_services?.isAvailable) {
                                                    dispatchAction(showNotification({ message: 'Not available at this location', type: 'error' }));
                                                    return;
                                                }
                                                else if (handleUpdateCheck()) {
                                                    dispatchAction(saveDeliveryServiceType(item));
                                                    router(`/${item?.label?.toLowerCase()}`)
                                                }
                                            }} key={item?.id} >
                                            <Avatar sx={{ width: 60, height: 60 }} src={item?.imgUrl} />
                                            <span style={{ fontSize: '11px', textTransform: "capitalize" }}>{item?.name?.toLowerCase()}</span>
                                        </Stack>
                                    )
                                }
                            </Stack>
                            {restaurants &&
                                <>
                                    <h4 style={{ fontSize: '18px', marginBottom: '5px' }}>Our Top Restaurants</h4>
                                    <Stack spacing={1} direction="row"
                                        style={{ display: 'flex', maxWidth: '100%', overflowX: 'scroll' }}>
                                        {restaurants?.map(item =>
                                            <Card onClick={() => {
                                                if (handleUpdateCheck()) {
                                                    const finalServices = {}
                                                    services.forEach((service) => finalServices[service?.name?.toLowerCase()] = service)
                                                    dispatchAction(saveDeliveryServiceType(finalServices['restaurants']));
                                                    router(`/restaurants/${item?.id}`);
                                                }
                                            }} key={item?.id} sx={{ maxWidth: 155, minWidth: 155, maxHeight: 205 }} style={{ borderRadius: 10 }}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        component="img"
                                                        height="90"
                                                        image={item?.imgUrl}
                                                        style={{ objectFit: 'contain', borderRadius: '10px' }}
                                                        alt="green iguana"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" style={{ fontSize: '12px' }}>
                                                            {item?.name}
                                                        </Typography>
                                                        <Typography style={{ fontSize: '10px' }} variant="body3" color="text.secondary">
                                                            {item?.description}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        )}
                                    </Stack>
                                </>
                            }
                            <Advertising advertising={carouselImages} services={services} />
                            <Box style={{ height: '80px' }} />
                        </Box>
                    </div>
                    <BottomNavigationComponent />
                    <LogoutMessage status={openLogoutMessage} handleClose={handleClose} />
                    <UpdateMessage />
                </div>
            </Main>
        </>
        }
    </>
    )
})