import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate } from 'react-router-dom'
import useDispatch from 'components/common/dispatchHook'
import { autoPlay } from 'react-swipeable-views-utils';
import { saveCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/actions';
import { saveDeliveryServiceType } from 'containers/DashBoard/actions';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper({ style, hideStepper, images, services }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images?.length;
  const router = useNavigate();
  const [dispatchAction, getStateData] = useDispatch();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };


  function handleClick(value) {
    const finalServices = {}
    services.forEach((service) => finalServices[service?.name?.toLowerCase()] = service)
    dispatchAction(saveCurrentRestaurant(value));
    if (value?.id !== "780317e0-0431-11ec-9d78-83739c56bd78") {
      if (value?.type === "RESTAURANT") {
        dispatchAction(saveDeliveryServiceType(finalServices['restaurants']));
        router && router(`/restaurants/${value?.id}`)
      } else if (value?.type === "GROCERY") {
        router && router(`/groceriesdashboard`)
        dispatchAction(saveDeliveryServiceType(finalServices['vegetables']));
      } else if (value?.type === "GROCERIES") {
        router && router(`/groceriesdashboard`)
        dispatchAction(saveDeliveryServiceType(finalServices[value.type.toLowerCase()]));
      } else if (value?.type === "KIRANA-SHOPS") {
        router && router(`/restaurants/${value?.id}`);
        dispatchAction(saveDeliveryServiceType(finalServices[value.type.toLowerCase()]));
      }
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }} style={{ zIndex: 9, ...style }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{ zIndex: 9, width: '100%' }}
      >
        {images?.map((step, index) => (
          <div
            onClick={() => handleClick(step?.hotel)}
            key={step.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  //   maxWidth: 400,
                  zIndex: 9,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step?.imgUrl}
                alt={step?.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {!hideStepper && <MobileStepper
        steps={maxSteps}
        position="static"
        style={{ marginfLeft: '50%' }}
        activeStep={activeStep || 0}
        nextButton={<span />
        }
        backButton={<span />
        }
      />}
    </Box>
  );
}

export default SwipeableTextMobileStepper;
