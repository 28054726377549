export const reducer = 'RestaurantPage';

export const ADD_DISH_NAME = reducer + '/ADD_DISH_NAME';
export const ADD_DISH_NAME_SUCCESS = reducer + '/ADD_DISH_NAME_SUCCESS';
export const ADD_DISH_NAME_FAILURE = reducer + '/ADD_DISH_NAME_FAILURE';

export const ADD_NEW_CATEGORY = reducer + '/ADD_NEW_CATEGORY';
export const ADD_NEW_CATEGORY_SUCCESS = reducer + '/ADD_NEW_CATEGORY_SUCCESS';
export const ADD_NEW_CATEGORY_FAILURE = reducer + '/ADD_NEW_CATEGORY_FAILURE';

export const ADD_DISH = reducer + '/ADD_DISH';
export const ADD_DISH_SUCCESS = reducer + '/ADD_DISH_SUCCESS';
export const ADD_DISH_FAILURE = reducer + '/ADD_DISH_FAILURE';

export const ADD_RESTAURANT = reducer + '/ADD_RESTAURANT';
export const ADD_RESTAURANT_SUCCESS = reducer + '/ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_FAILURE = reducer + '/ADD_RESTAURANT_FAILURE';

export const GET_DISH_NAME = reducer + '/GET_DISH_NAME';
export const GET_DISH_NAME_SUCCESS = reducer + '/GET_DISH_NAME_SUCCESS';
export const GET_DISH_NAME_FAILURE = reducer + '/GET_DISH_NAME_FAILURE';

export const GET_DISHES_CATEGORY = reducer + '/GET_DISHES_CATEGORY';
export const GET_DISHES_CATEGORY_SUCCESS = reducer + '/GET_DISHES_CATEGORY_SUCCESS';
export const GET_DISHES_CATEGORY_FAILURE = reducer + '/GET_DISHES_CATEGORY_FAILURE';

export const GET_RESTAURANT_INFO = reducer + '/GET_RESTAURANT_INFO';
export const GET_RESTAURANT_INFO_SUCCESS = reducer + '/GET_RESTAURANT_INFO_SUCCESS';
export const GET_RESTAURANT_INFO_FAILURE = reducer + '/GET_RESTAURANT_INFO_FAILURE';

export const UPDATE_RESTAURANT_INFO = reducer + '/UPDATE_RESTAURANT_INFO';
export const UPDATE_RESTAURANT_INFO_SUCCESS = reducer + '/UPDATE_RESTAURANT_INFO_SUCCESS';
export const UPDATE_RESTAURANT_INFO_FAILURE = reducer + '/UPDATE_RESTAURANT_INFO_FAILURE';

export const GET_RESTAURANT_PAYMENTS = reducer + '/GET_RESTAURANT_PAYMENTS';
export const GET_RESTAURANT_PAYMENTS_SUCCESS = reducer + '/GET_RESTAURANT_PAYMENTS_SUCCESS';
export const GET_RESTAURANT_PAYMENTS_FAILURE = reducer + '/GET_RESTAURANT_PAYMENTS_FAILURE';

export const SAVE_PAYMENTS_DATE = reducer + '/SAVE_PAYMENTS_DATE';

export const ADD_CAROUSEL = reducer + '/ADD_CAROUSEL';
export const ADD_CAROUSEL_SUCCESS = reducer + '/ADD_CAROUSEL_SUCCESS';
export const ADD_CAROUSEL_FAILURE = reducer + '/ADD_CAROUSEL_FAILURE';

export const UPDATE_CAROUSEL = reducer + '/UPDATE_CAROUSEL';
export const UPDATE_CAROUSEL_SUCCESS = reducer + '/UPDATE_CAROUSEL_SUCCESS';
export const UPDATE_CAROUSEL_FAILURE = reducer + '/UPDATE_CAROUSEL_FAILURE';

export const ADD_COUPON = reducer + '/ADD_COUPON';
export const ADD_COUPON_SUCCESS = reducer + '/ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAILURE = reducer + '/ADD_COUPON_FAILURE';

export const UPDATE_COUPON = reducer + '/UPDATE_COUPON';
export const UPDATE_COUPON_SUCCESS = reducer + '/UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = reducer + '/UPDATE_COUPON_FAILURE';