import { Alert, Snackbar, Slide } from '@mui/material';
import * as React from 'react';


export const showToast = (message, severity = "success") => {
    const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    return (
        <Snackbar TransitionComponent={<Slide direction="up" />} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    );
};
