import {SAVE_ACCOUNT_DETAILS, VERIFY_NUMBER} from './constants';

export const saveAccountDetails = (payload) => ({
  type: SAVE_ACCOUNT_DETAILS,
  payload,
});

export const verifyPhoneNumber = (payload) => ({
  type: VERIFY_NUMBER,
  payload,
});
