import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Slide
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ title,
    message,
    cancelText = "Cancel",
    okText = "I agree, proceed",
    showConfirm,
    handleCancel,
    handleOk
 }) => {
    return (
        <Dialog
            open={showConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleCancel()}
            aria-describedby="alert-dialog-slide-description"
            sx={{zIndex: 99999}}
        >
            <DialogTitle textAlign={"center"} >{title}</DialogTitle>
            <DialogContent>
                <DialogContentText textAlign={"center"} id="alert-dialog-slide-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-evenly"}} >
                <Button onClick={() => handleCancel()} variant='outlined' color='error'>{cancelText}</Button>
                <Button onClick={() => handleOk()} variant='outlined' color="primary">{okText}</Button>
            </DialogActions>
        </Dialog>
    )
}