import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT } from 'restClient';
import { activeLocation } from './reducer';

function* _get_locations({ payload }) {
  const location = yield select(activeLocation);
  try {
    const response = yield call(GET, { url: '/location/fetch' });
    yield put({
      type: types.GET_LOCATIONS_SUCCESS,
      payload: response.data.message,
    });
    var isDefault = response.data.message.find((loc) => loc.isDefault);
    if(location?.name) isDefault = location;
    yield put({
      type: types.SAVE_CURRENT_LOCATION,
      // payload: response.data.message.find((loc) => loc.isDefault),
      payload: isDefault,
    });
    if (isDefault) {
      yield put({
        type: types.GET_SERVICES,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_LOCATIONS_FAILURE, payload: error });
  }
}
function* _get_services({ payload }) {
  try {
    const location = yield select(activeLocation);
    const data = {
      url: '/location/fetch-services',
      params: { locationId: location?.id },
    };
    const response = yield call(GET, data);
    yield put({
      type: types.GET_SERVICES_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_SERVICES_FAILURE, payload: error });
  }
}
function* _get_carousel_images({ payload }) {
  const location = yield select(activeLocation);
  try {
    const data = {
      url: '/location/fetch-carousel',
      // params: {},
      params: { ...payload, locationId: location?.id },
    };
    const response = yield call(GET, data);
    // console.log(response,'carousel');
    yield put({
      type: types.GET_CAROUSEL_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_CAROUSEL_FAILURE, payload: error });
  }
}
function* _get_top_list({ payload }) {
  try {
    const location = yield select(activeLocation);
    const data = {
      url: '/location/top-restaurants',
      params: { ...payload, locationId: location?.id },
    };
    const response = yield call(GET, data);
    // console.log(response);
    yield put({
      type: types.GET_TOP_RESTAURANTS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_TOP_RESTAURANTS_FAILURE, payload: error });
  }
}
const _get_locations_watcher = takeEvery(types.GET_LOCATIONS, _get_locations);
const _get_services_watcher = takeEvery(types.GET_SERVICES, _get_services);
const _get_carousel_images_watcher = takeEvery(types.GET_CAROUSEL, _get_carousel_images);
const _get_top_list_watcher = takeEvery(types.GET_TOP_RESTAURANTS, _get_top_list);

export default function* mainSaga() {
  yield all([_get_locations_watcher, _get_services_watcher, _get_carousel_images_watcher, _get_top_list_watcher]);
}
