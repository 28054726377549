import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Stack,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,

} from "@mui/material";
// import { advertising } from "./helper";
import { useNavigate } from 'react-router-dom'
import useDispatch from 'components/common/dispatchHook'
import { saveCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/actions';
import { saveDeliveryServiceType } from 'containers/DashBoard/actions';

export default function Advertising({ advertising, services }) {
  const router = useNavigate();
  const [dispatchAction] = useDispatch();


  function handleClick(value) {
    const finalServices = {}
    services.forEach((service) => finalServices[service?.name?.toLowerCase()] = service)
    dispatchAction(saveCurrentRestaurant(value));
    if (value?.id !== "780317e0-0431-11ec-9d78-83739c56bd78") {
      if (value?.type === "RESTAURANT") {
        dispatchAction(saveDeliveryServiceType(finalServices['restaurants']));
        router && router(`/restaurants/${value?.id}`)
      } else if (value?.type === "GROCERY") {
        router && router(`/groceriesdashboard`)
        dispatchAction(saveDeliveryServiceType(finalServices['vegetables']));
      } else if (value?.type === "GROCERIES") {
        router && router(`/groceriesdashboard`)
        dispatchAction(saveDeliveryServiceType(finalServices[value.type.toLowerCase()]));
      } else if (value?.type === "KIRANA-SHOPS") {
        router && router(`/restaurants/${value?.id}`);
        dispatchAction(saveDeliveryServiceType(finalServices[value.type.toLowerCase()]));
      }
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {advertising?.filter((va) => va?.hotel_id !== "780317e0-0431-11ec-9d78-83739c56bd78")?.length > 0 &&
        <h3 style={{fontSize: '16px'}}>What's Happening?</h3>}
      <Stack spacing={1} direction="column"
        style={{ padding: 0, display: 'flex', maxWidth: '100%', overflowX: 'scroll', alignItems: "center" }}>
        {advertising &&
          advertising?.filter((va) => va?.hotel_id !== "780317e0-0431-11ec-9d78-83739c56bd78").map(item =>
            <Card
              key={item?.id}
              sx={{
                width: "95%",
                maxHeight: 305
              }}
              style={{ borderRadius: 10 }}
              onClick={() => handleClick(item?.hotel)}
            >

              <CardActionArea>
                <CardMedia
                  component="img"
                  height="255"
                  image={item?.imgUrl}
                  style={{ objectFit: 'contain' }}
                  alt="green iguana"
                />
                <CardContent sx={{ padding: "1%", paddingLeft: "4%" }} >
                  <Typography gutterBottom variant="h5" style={{ fontSize: '12px' }}>
                    {item?.title || item?.hotel?.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )
        }
      </Stack>
    </Box>
  );
}




