import {
  ADD_ORDER,
  UPDATE_ADDRESS,
  GET_DELIVERY_ADDRESS,
  ADD_NEW_ADDRESS,
  SAVE_CURRENT_DELIVERY_ADDRESS,
  SAVE_SELECTED_COUPON,
  GET_COUPONS,
} from './constants';

export const addOrder = (payload) => ({
  type: ADD_ORDER,
  payload,
});
export const addNewAddress = (payload) => ({
  type: ADD_NEW_ADDRESS,
  payload,
});

export const updateAddress = (payload) => ({
  type: UPDATE_ADDRESS,
  payload,
});
export const getDeliveryAddress = (payload) => ({
  type: GET_DELIVERY_ADDRESS,
  payload,
});

export const saveCurrentDeliveryAddress = (payload) => ({
  type: SAVE_CURRENT_DELIVERY_ADDRESS,
  payload,
});

export const saveSelectedCoupon = (payload) => ({
  type: SAVE_SELECTED_COUPON,
  payload,
});

export const getCoupons = (payload) => ({
  type: GET_COUPONS,
  payload,
});