import React from 'react';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SettingsIcon from '@mui/icons-material/Settings';


// export const images = [
//   {
//     label: 'San Francisco – Oakland Bay Bridge, United States',
//     imgPath:
//       'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bird',
//     imgPath:
//       'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bali, Indonesia',
//     imgPath:
//       'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//   },
//   {
//     label: 'Goč, Serbia',
//     imgPath:
//       'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//   },
// ];

//   export const serviceIcons = [
//     { title: 'Restaurants', Icon: DinnerDiningTwoToneIcon, color: 'green' },
//     { title: 'Vegetables', Icon: KebabDiningTwoToneIcon, color: 'purple' },
//     { title: 'Grocery', Icon: LocalGroceryStoreTwoToneIcon, color: 'orange' },
//     { title: 'Kirana-Shops', Icon: StoreMallDirectoryTwoToneIcon, color: 'blue' },
//     { title: 'Burger', Icon: LunchDiningTwoToneIcon, color: 'red' }
// ]

export const sideMenu = [
  { title: 'My Orders', path: '/userOrders', Icon: <DeliveryDiningIcon />, },
  { title: 'My Address', path: '/address', Icon: <AlternateEmailIcon />, },
  { title: 'Settings', path: '/settings', Icon: <SettingsIcon />, },
  // { title: 'Contact Us', path: '/dashboard', Icon: <InboxIcon />, },
  // { title: 'About Us', path: '/dashboard', Icon: <InboxIcon />, },

]
export const sideOwnerMenu = [
  { title: 'My Restaurant Page', path: '/myrestaurantpage', Icon: <AccountBalanceIcon />, },
  { title: 'Customer Orders', path: '/restaurantOrders', Icon: <DeliveryDiningIcon />, },
]

export const advertising = [
  {id:"1", title: 'My firm Page', description: 'jsbfsjbfsbfsbshfbsfbshbfbshfbshbhfbsf', imgUrl: 'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60' },
  {id: "2", title: 'Customer Orders',  description: 'jsbfsjbfsbfsbshfbsfbshbfbshfbshbhfbsf',imgUrl: 'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60' },
]
