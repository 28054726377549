import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import * as types from './constants';
import { GET_RESTAURANTS } from 'containers/Restaurants/RestaurantsList/constants';
import {
  RESTAURANT_ADDED,
  RESTAURANT_UPDATED,
} from 'containers/LoginPage/constants';
import { GET, POST, PUT } from 'restClient';
import { activeLocation } from 'containers/DashBoard/reducer';
import { getLoggedInUser } from 'containers/LoginPage/reducer';
import { getCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/reducer';
// import { navigate } from 'utils/navigationService';
import { showToast } from 'utils/notification';
import * as notificationtTypes from 'containers/DashBoard/constants';
import * as dashboardTypes from 'containers/DashBoard/constants'
import * as cartPageTypes from 'containers/CartPage/constants'
import * as dishesTypes from 'containers/Restaurants/RestaurantDishes/constants'
function* _add_dish_name({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/restaurant/create-dish-name',
      requestBody: payload,
    });
    yield put({ type: types.ADD_DISH_NAME_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ADD_DISH_NAME_FAILURE, payload: error });
  }
}
function* _add_category({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/restaurant/create-category',
      requestBody: payload,
    });
    yield put({ type: types.ADD_NEW_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ADD_NEW_CATEGORY_FAILURE, payload: error });
  }
}

function* _add_dish({ payload }) {
  try {
    const HotelId = yield select(getCurrentRestaurant);
    const response = yield call(POST, {
      url: '/restaurant/create-dish',
      requestBody: payload,
    });
    yield put({ type: types.ADD_DISH_SUCCESS, payload: response.data });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Item added Successfully', type: 'success' } });
    yield put({type: dishesTypes.GET_DISHES, payload: HotelId.id});
  } catch (error) {
    yield put({ type: types.ADD_DISH_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Item add failed', type: 'error' } });
  }
}
function* _add_restaurant({ payload }) {
  try {
    const location = yield select(activeLocation);
    const user = yield select(getLoggedInUser);
    if (!location) {
      throw Error('Location Must be Selected');
    }
    if (!user) {
      // navigate('LoginPage');
      throw Error('User Must be Logged In');
    }
    const requestBody = {
      ...payload,
      owner_id: user.id,
      location_id: location?.id,
    };
    const response = yield call(POST, { url: '/restaurant/create', requestBody });
    yield put({
      type: types.ADD_RESTAURANT_SUCCESS,
      payload: response.data.message,
    });
    yield put({
      type: RESTAURANT_ADDED,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.ADD_RESTAURANT_FAILURE, payload: error });
    // yield call(showToast, error.message);
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add firm failed', type: 'error' } });
    
  }
}

function* _get_dish_Name({ payload }) {
  try {
    const response = yield call(GET, { url: '/restaurant/fetch-dish-name' });
    yield put({
      type: types.GET_DISH_NAME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_DISH_NAME_FAILURE, payload: error });
  }
}
function* _get_dishes_category({ payload }) {
  try {
    const response = yield call(GET, { url: '/restaurant/fetch-category' });
    yield put({
      type: types.GET_DISHES_CATEGORY_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_DISHES_CATEGORY_FAILURE, payload: error });
  }
}

function* _get_restaurant_info({ payload }) {
  try {
    const userData = yield select(getLoggedInUser);
    const id = userData.id;
    const response = yield call(GET, {
      url: '/restaurant/fetch-restaurant',
      params: { userId: id },
    });
    yield put({
      type: types.GET_RESTAURANT_INFO_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_RESTAURANT_INFO_FAILURE, payload: error });
    // yield call(showToast, 'failed to get Restaurantinfo');
    
  }
}

function* _update_restaurant_info({ payload }) {
  try {
    // const user = yield select(getLoggedInUser);
    const response = yield call(PUT, {
      url: '/restaurant/update/' + payload.id,
      requestBody: payload,
    });
    yield put({
      type: types.UPDATE_RESTAURANT_INFO_SUCCESS,
      payload: response.data.message,
    });
    yield put({
      type: RESTAURANT_UPDATED,
      payload,
    });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated Successfully', type: 'success' } });
    // yield put({type: GET_RESTAURANTS, payload: {userId: user?.id}});
  } catch (error) {
    yield put({ type: types.UPDATE_RESTAURANT_INFO_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated failed', type: 'error' } });
    // yield call(showToast, 'Update failed');
  }
}

function* _get_restaurant_payments({ payload }) {
  const Hotel = yield select(getCurrentRestaurant);
  try {
    const response = yield call(GET, {
      url: '/restaurant/restaurant-payments',
      params: { ...payload, hotelId: Hotel.id },
    });
    yield put({ type: types.GET_RESTAURANT_PAYMENTS_SUCCESS, payload: response.data.message });
  } catch (error) {
    yield put({ type: types.GET_RESTAURANT_PAYMENTS_FAILURE, payload: error });
  }
}
function* _add_carousel({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/location/add-carousel-image',
      requestBody: payload,
    });
    yield put({ type: types.ADD_CAROUSEL_SUCCESS, payload: response.data });
    yield put({type: dashboardTypes.GET_CAROUSEL, payload: {}});
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add carousel Successfully', type: 'success' } });
  } catch (error) {
    yield put({ type: types.ADD_CAROUSEL_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add acarousel failed', type: 'error' } });
  }
}

function* _update_carousel({ payload }) {
  try {
    const response = yield call(PUT, {
      url: '/location/update-carousel/' + payload?.id,
      requestBody: payload.body
    });
    yield put({
      type: types.UPDATE_CAROUSEL_SUCCESS,
      payload: response.data.message,
    });
    yield put({type: dashboardTypes.GET_CAROUSEL, payload: {}});
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated Successfully', type: 'success' } });
  } catch (error) {
    yield put({ type: types.UPDATE_CAROUSEL_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated failed', type: 'error' } });
  }
}

function* _add_coupon({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/coupons/create',
      requestBody: payload,
    });
    yield put({ type: types.ADD_COUPON_SUCCESS, payload: response.data });
    yield put({type: cartPageTypes.GET_COUPONS, payload: {}});
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add coupon Successfully', type: 'success' } });
  } catch (error) {
    yield put({ type: types.ADD_COUPON_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Add coupon failed', type: 'error' } });
  }
}

function* _update_coupon({ payload }) {
  try {
    const response = yield call(PUT, {
      url: '/coupons/update',
      requestBody: payload
    });
    // console.log(response,'response');
    yield put({
      type: types.UPDATE_COUPON_SUCCESS,
      payload: response.data.message,
    });
    yield put({type: cartPageTypes.GET_COUPONS, payload: {}});
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated Successfully', type: 'success' } });
  } catch (error) {
    yield put({ type: types.UPDATE_COUPON_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Updated failed', type: 'error' } });
  }
}

const _add_dish_name_watcher = takeEvery(types.ADD_DISH_NAME, _add_dish_name);
const _add_category_watcher = takeEvery(types.ADD_NEW_CATEGORY, _add_category);
const _add_dish_watcher = takeEvery(types.ADD_DISH, _add_dish);
const _add_restaurant_watcher = takeEvery(types.ADD_RESTAURANT, _add_restaurant,);
const _get_dish_Name_watcher = takeEvery(types.GET_DISH_NAME, _get_dish_Name);
const _get_dishes_category_watcher = takeEvery(
  types.GET_DISHES_CATEGORY,
  _get_dishes_category,
);
const _get_restaurant_info_watcher = takeEvery(
  types.GET_RESTAURANT_INFO,
  _get_restaurant_info,
);
const _update_restaurant_info_watcher = takeEvery(
  types.UPDATE_RESTAURANT_INFO,
  _update_restaurant_info,
);
const _get_restaurant_payments_watcher = takeEvery(types.GET_RESTAURANT_PAYMENTS, _get_restaurant_payments,);
const _update_carousel_watcher = takeEvery(types.UPDATE_CAROUSEL, _update_carousel,);
const _add_carousel_watcher = takeEvery(types.ADD_CAROUSEL, _add_carousel,);
const _update_coupon_watcher = takeEvery(types.UPDATE_COUPON, _update_coupon,);
const _add_coupon_watcher = takeEvery(types.ADD_COUPON, _add_coupon,);

export default function* mainSaga() {
  yield all([
    _add_category_watcher,
    _add_dish_name_watcher,
    _add_dish_watcher,
    _add_restaurant_watcher,
    _get_dish_Name_watcher,
    _get_dishes_category_watcher,
    _get_restaurant_info_watcher,
    _update_restaurant_info_watcher,
    _get_restaurant_payments_watcher,
    _update_carousel_watcher,
    _add_carousel_watcher,
    _update_coupon_watcher,
    _add_coupon_watcher
  ]);
}
