import React from "react";
import { Avatar, Stack, Badge } from '@mui/material';
import useDispatch from 'components/common/dispatchHook'
import { getlocationsList, isLoading, activeLocation } from "containers/DashBoard/reducer";
import { loadProgress } from "components/common/helper";
import { getCurrentTheme } from "global/ThemeReducer";
import { saveCurrentLocation, getServices, getCarousel } from "containers/DashBoard/actions";
import { getRestaurants } from "containers/Restaurants/RestaurantsList/actions";
import DoneIcon from '@mui/icons-material/Done';
const Locations = ({ onSelect }) => {
    const [dispatchAction, getStateData] = useDispatch();
    const locationsList = getStateData(getlocationsList);
    const currentLocation = getStateData(activeLocation);
    const loading = getStateData(isLoading);
    // const { palette } = getStateData(getCurrentTheme)

    function handleClick(obj) {
        if (obj?.id !== currentLocation?.id) {
            dispatchAction(saveCurrentLocation(obj));
            dispatchAction(getServices());
            dispatchAction(getRestaurants({ status: "APPROVED" }));
            dispatchAction(getCarousel())
        }
        onSelect({ location: obj });
    }


    return (
        <>
            {loading ? loadProgress() :

                <Stack direction="row" spacing={4} id="custom-scroll" justifyContent={"left"}
                    style={{
                        padding: "4%", maxWidth: '100%', overflowX: 'scroll',
                        // backgroundColor: palette.mode === 'light' ? '#F78F35' : '#671a1a',
                    }}
                >
                    {locationsList &&
                        locationsList?.map(item =>
                            <Stack alignItems={"center"} textAlign={"center"}
                                key={item?.id}
                                onClick={() => handleClick(item)}
                            >
                                {currentLocation?.id === item?.id ?
                                    <Badge
                                        style={{ padding: 0 }}
                                        color="secondary" badgeContent={<DoneIcon fontSize="6px" />}
                                    >
                                        <Avatar sx={{ bgcolor: "green", width: 35, height: 35 }}>
                                            {item?.name?.substring(0, 1)}
                                        </Avatar>
                                    </Badge>
                                    :
                                    <Avatar sx={{ bgcolor: "lightgray", width: 35, height: 35 }}>
                                        {item?.name?.substring(0, 1)}
                                    </Avatar>
                                }
                                <span style={{ fontSize: '12px', textTransform: "capitalize" }}>{item?.name}</span>
                            </Stack>
                        )
                    }
                </Stack>
            }
        </>
    );
}
export default Locations