import {put, takeEvery, all, call, select} from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT} from 'restClient';
import {getCurrentRestaurant} from 'containers/Restaurants/RestaurantsList/reducer';
import {getLoggedInUser} from 'containers/LoginPage/reducer';
import * as notificationtTypes from 'containers/DashBoard/constants';

function* _update_dish({payload}) {
  const HotelId = yield select(getCurrentRestaurant);
  try {
    // const HotelId = yield select(getLoggedInUser);
    yield call(PUT, {
      url: '/restaurant/update-dish/' + payload.dishId,
      requestBody: payload.state,
    });
    yield put({type: types.UPDATE_DISH_SUCCESS, payload});
    yield put({type: types.DISH_UPDATED, payload: payload});
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'Item update Success', type: 'success'}});
    // yield put({type: types.GET_DISHES, payload: HotelId.id});
  } catch (error) {
    yield put({type: types.UPDATE_DISH_FAILURE, payload: error});
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'Item update failed', type: 'error'}});
    // yield put({type: types.GET_DISHES, payload: HotelId.id});
}
}

function* _get_dishes({payload}) {
  try {
    const HotelId = yield select(getCurrentRestaurant);
    const response = yield call(GET, {
      url: '/restaurant/fetch-dish',
      params: {hotel_id: payload ? payload : HotelId.id},
    });
    yield put({type: types.GET_DISHES_SUCCESS, payload: response.data.message});
    yield put({type: types.SAVE_CURRENT_ITEM, payload: {}});
  } catch (error) {
    yield put({type: types.GET_DISHES_FAILURE, payload: error});
  }
}
function* _get_dishes_category({payload}) {
  try {
    const response = yield call(GET, {url: '/restaurant/fetch-category'});
    yield put({
      type: types.GET_DISHES_CATEGORY_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({type: types.GET_DISHES_CATEGORY_FAILURE, payload: error});
  }
}

function* _update_dish_array({payload}) {
  try {
    // const HotelId = yield select(getCurrentRestaurant);
    const HotelId = yield select(getLoggedInUser);
    yield call(PUT, {
      url: '/restaurant/update-disharray',
      requestBody: payload,
    });
    yield put({type: types.UPDATE_DISH_ARRAY_SUCCESS, payload});
    yield put({type: types.GET_DISHES, payload: HotelId.hotels[0]?.id});
  } catch (error) {
    yield put({type: types.UPDATE_DISH_ARRAY_FAILURE, payload: error});
  }
}

const _update_dish_watcher = takeEvery(types.UPDATE_DISH, _update_dish);
const _update_dish_array_watcher = takeEvery(types.UPDATE_DISH_ARRAY, _update_dish_array);
const _get_dishes_watcher = takeEvery(types.GET_DISHES, _get_dishes);
const _get_dishes_category_watcher = takeEvery(
  types.GET_DISHES_CATEGORY,
  _get_dishes_category,
);

export default function* mainSaga() {
  yield all([
    _get_dishes_watcher,
    _update_dish_watcher,
    _get_dishes_category_watcher,
    _update_dish_array_watcher,
  ]);
}
