import * as types from './constants';

const initialState = {
  loading: false,
  accountDetails: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.SAVE_ACCOUNT_DETAILS_SUCCESS:
      return {...state, loading: false, accountDetails: payload};
    case types.SAVE_ACCOUNT_DETAILS_FAILURE:
      return {...state, loading: false, ageDetailsSubmitted: false};

    case types.SAVE_ACCOUNT_DETAILS:
    case types.VERIFY_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case types.VERIFY_NUMBER_SUCCESS:
    case types.VERIFY_NUMBER_FAILURE:
      return {
        ...state, loading: false,
      };

    default:
      return state;
  }
};

export const reducer = types.reducer;
export const getAccountDetails = (state) => state[reducer].accountDetails;
export const getRegLoading = (state) => state[reducer].loading;
