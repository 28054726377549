import { all, fork } from "redux-saga/effects";
import LoginPage from 'containers/LoginPage/sagas';
import DashBoard from 'containers/DashBoard/sagas';
import Groceries from 'containers/Groceries/GroceriesList/sagas';
import CartPage from 'containers/CartPage/sagas';
import Registration from 'containers/Registration/sagas';
import RestaurantDishes from 'containers/Restaurants/RestaurantDishes/sagas';
import RestaurantOrders from 'containers/Restaurants/RestaurantOrders/sagas';
import RestaurantPage from 'containers/Restaurants/RestaurantPage/sagas';
import RestaurantsList from 'containers/Restaurants/RestaurantsList/sagas';
import UserOrders from 'containers/Restaurants/UserOrders/sagas';

export default function* rootSaga() {
  //   yield all([introSaga(), phoneSaga(), AgeDetailsSaga()]);

  yield all([
    fork(LoginPage),
    fork(DashBoard),
    fork(Groceries),
    fork(CartPage),
    fork(Registration),
    fork(RestaurantDishes),
    fork(RestaurantOrders),
    fork(RestaurantPage),
    fork(RestaurantsList),
    fork(UserOrders),
  ]);
}
