import { put, takeEvery, all, call } from 'redux-saga/effects';
import * as types from './constants';
import * as loginTypes from '../LoginPage/constants';
import { POST } from 'restClient';
import * as notificationtTypes from 'containers/DashBoard/constants';

function* _save_account_details({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/user/create',
      requestBody: payload.cred,
    });
    yield put({
      type: types.SAVE_ACCOUNT_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: loginTypes.LOGIN_USER,
      payload: {
        cred: {
          username: payload?.cred?.pno,
          password: payload?.cred?.password,
          deviceToken: payload?.cred?.deviceToken
        },
        router: payload.router
      },
    });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Registration Success', type: 'success' } });
  } catch (error) {
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Oops... Registration Failed, Please try again', type: 'error' } });
    yield put({ type: types.SAVE_ACCOUNT_DETAILS_FAILURE, payload: error });
  }
}

function* _verify_phone_number({ payload }) {
  const { pno, resolve, reject } = payload;
  try {
    const response = yield call(POST, {
      url: `/user/verify-number?pno=${pno}`,
    });
    if (!response?.data?.message?.duplicate) {
      yield call(resolve, 'Phone Number is not duplicate');
      return;
    }
    yield call(reject, 'Phone number is already exists');
    yield put({ type: types.VERIFY_NUMBER_SUCCESS, payload: '' });
  } catch (error) {
    yield call(reject, 'Phone number is already exists');
    yield put({ type: types.VERIFY_NUMBER_FAILURE, payload: '' });


  }
}

const _save_age_account_watcher = takeEvery(
  types.SAVE_ACCOUNT_DETAILS,
  _save_account_details,
);

const _verify_phone_number_watcher = takeEvery(
  types.VERIFY_NUMBER,
  _verify_phone_number,
);

export default function* mainSaga() {
  yield all([_save_age_account_watcher, _verify_phone_number_watcher]);
}
