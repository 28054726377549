import {
  ADD_DISH,
  ADD_DISH_FAILURE,
  ADD_DISH_SUCCESS,
  ADD_DISH_NAME,
  ADD_DISH_NAME_FAILURE,
  ADD_DISH_NAME_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_NEW_CATEGORY_FAILURE,
  ADD_NEW_CATEGORY_SUCCESS,
  reducer,
  GET_DISH_NAME,
  GET_DISH_NAME_FAILURE,
  GET_DISH_NAME_SUCCESS,
  ADD_RESTAURANT,
  ADD_RESTAURANT_FAILURE,
  ADD_RESTAURANT_SUCCESS,
  GET_DISHES_CATEGORY,
  GET_DISHES_CATEGORY_FAILURE,
  GET_DISHES_CATEGORY_SUCCESS,
  GET_RESTAURANT_INFO,
  GET_RESTAURANT_INFO_FAILURE,
  GET_RESTAURANT_INFO_SUCCESS,
  UPDATE_RESTAURANT_INFO,
  UPDATE_RESTAURANT_INFO_FAILURE,
  UPDATE_RESTAURANT_INFO_SUCCESS,
  GET_RESTAURANT_PAYMENTS,
  GET_RESTAURANT_PAYMENTS_FAILURE,
  GET_RESTAURANT_PAYMENTS_SUCCESS,
  ADD_CAROUSEL,
  ADD_CAROUSEL_FAILURE,
  ADD_CAROUSEL_SUCCESS,
  UPDATE_CAROUSEL,
  UPDATE_CAROUSEL_FAILURE,
  UPDATE_CAROUSEL_SUCCESS,
  ADD_COUPON,
  ADD_COUPON_FAILURE,
  ADD_COUPON_SUCCESS,
  UPDATE_COUPON,
  UPDATE_COUPON_FAILURE,
  UPDATE_COUPON_SUCCESS,
  SAVE_PAYMENTS_DATE,
} from './constants';

const initialState = {
  loading: false,
  dishList: [],
  dishCategoryList: [],
  restaurantInfoList: [],
  createdDish: null,
  createdCategory: null,
  resPaymentsList: [],
  paymentsDate: {},
};
4;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_DISH_SUCCESS:
    case ADD_RESTAURANT_SUCCESS:
    case UPDATE_RESTAURANT_INFO_SUCCESS:
    case UPDATE_CAROUSEL_SUCCESS:
    case ADD_CAROUSEL_SUCCESS:
    case UPDATE_COUPON_SUCCESS:
    case ADD_COUPON_SUCCESS:
      return { ...state, loading: false };

    case ADD_DISH_NAME_FAILURE:
    case ADD_DISH_FAILURE:
    case ADD_NEW_CATEGORY_FAILURE:
    case ADD_RESTAURANT_FAILURE:
    case GET_DISH_NAME_FAILURE:
    case GET_DISHES_CATEGORY_FAILURE:
    case GET_RESTAURANT_INFO_FAILURE:
    case UPDATE_RESTAURANT_INFO_FAILURE:
    case GET_RESTAURANT_PAYMENTS_FAILURE:
    case UPDATE_CAROUSEL_FAILURE:
    case ADD_CAROUSEL_FAILURE:
    case UPDATE_COUPON_FAILURE:
    case ADD_COUPON_FAILURE:
      return { ...state, loading: false };

    case ADD_DISH:
    case ADD_DISH_NAME:
    case ADD_NEW_CATEGORY:
    case ADD_RESTAURANT:
    case GET_DISH_NAME:
    case GET_DISHES_CATEGORY:
    case GET_RESTAURANT_INFO:
    case UPDATE_RESTAURANT_INFO:
    case GET_RESTAURANT_PAYMENTS:
    case ADD_CAROUSEL:
    case UPDATE_CAROUSEL:
    case ADD_COUPON:
    case UPDATE_COUPON:
      return {
        ...state,
        loading: true,
      };
    case ADD_DISH_NAME_SUCCESS: {
      return {
        ...state,
        createdDish: payload,
        loading: false,
      };
    }
    case ADD_NEW_CATEGORY_SUCCESS: {
      return {
        ...state,
        createdCategory: payload,
        loading: false,
      };
    }
    case GET_DISH_NAME_SUCCESS: {
      return {
        ...state,
        dishList: payload,
        loading: false,
      };
    }
    case GET_DISHES_CATEGORY_SUCCESS: {
      return {
        ...state,
        dishCategoryList: payload,
        loading: false,
      };
    }
    case GET_RESTAURANT_INFO_SUCCESS: {
      return {
        ...state,
        restaurantInfoList: payload,
        loading: false,
      };
    }
    case GET_RESTAURANT_PAYMENTS_SUCCESS: {
      return {
        ...state,
        resPaymentsList: payload,
        loading: false,
      };
    }
    case SAVE_PAYMENTS_DATE: {
      return {
        ...state,
        paymentsDate: payload,
      };
    }
    default:
      return state;
  }
};

export const getNewDishName = (state) => {
  return state[reducer].createdDish;
};
export const getNewCategory = (state) => {
  return state[reducer].createdCategory;
};
export const getDishNameList = (state) => {
  return state[reducer].dishList;
};
export const getDishCategoryList = (state) => {
  return state[reducer].dishCategoryList;
};
export const getRestaurantInfoList = (state) => {
  return state[reducer].restaurantInfoList;
};
export const getresPaymentsList = (state) => {
  return state[reducer].resPaymentsList;
};
export const getPaymentsDate = (state) => {
  return state[reducer].paymentsDate;
};
export const isLoading = (state) => {
  return state[reducer].loading;
};
export { reducer };
