import { combineReducers } from "redux";
import Theme, { reducer as THEME_REDUCER } from "./global/ThemeReducer";
import LoginPage, { reducer as LoginReducer, } from 'containers/LoginPage/reducer';
import DashBoard, { reducer as DashBoardReducer, } from 'containers/DashBoard/reducer';
import Groceries, { reducer as GroceriesReducer, } from 'containers/Groceries/GroceriesList/reducer';
import CartPage, { reducer as CartReducer, } from 'containers/CartPage/reducer';
import Registration, { reducer as RegistrationReducer, } from 'containers/Registration/reducer';
import RestaurantDishes, { reducer as RestaurantDishesReducer, } from 'containers/Restaurants/RestaurantDishes/reducer';
import RestaurantOrders, { reducer as RestaurantOrdersReducer, } from 'containers/Restaurants/RestaurantOrders/reducer';
import RestaurantPage, { reducer as RestaurantPageReducer, } from 'containers/Restaurants/RestaurantPage/reducer';
import RestaurantsList, { reducer as RestaurantsListReducer, } from 'containers/Restaurants/RestaurantsList/reducer';
import UserOrders, { reducer as UserOrdersReducer, } from 'containers/Restaurants/UserOrders/reducer';

export default combineReducers({
    [THEME_REDUCER]: Theme,
    [LoginReducer]: LoginPage,
    [DashBoardReducer]: DashBoard,
    [GroceriesReducer]: Groceries,
    [CartReducer]: CartPage,
    [RegistrationReducer]: Registration,
    [RestaurantDishesReducer]: RestaurantDishes,
    [RestaurantOrdersReducer]: RestaurantOrders,
    [RestaurantPageReducer]: RestaurantPage,
    [RestaurantsListReducer]: RestaurantsList,
    [UserOrdersReducer]: UserOrders,
});