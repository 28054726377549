import {
  GET_LOCATIONS,
  GET_SERVICES,
  SAVE_CURRENT_LOCATION,
  SAVE_DELIVERY_SERVICE_TYPE,
  GET_CAROUSEL,
  SHOW_NOTIFICATION,
  GET_TOP_RESTAURANTS
} from './constants';

export const getLocations = (payload) => ({
  type: GET_LOCATIONS,
  payload,
});

export const getServices = (payload) => ({
  type: GET_SERVICES,
  payload,
});

export const saveCurrentLocation = (payload) => ({
  type: SAVE_CURRENT_LOCATION,
  payload,
});

export const saveDeliveryServiceType = (payload) => ({
  type: SAVE_DELIVERY_SERVICE_TYPE,
  payload,
});

export const getCarousel = (payload) => ({
  type: GET_CAROUSEL,
  payload,
});

export const showNotification = (payload) => ({
  type: SHOW_NOTIFICATION,
  payload,
});
export const getTopRestaurants = (payload) => ({
  type: GET_TOP_RESTAURANTS,
  payload,
});