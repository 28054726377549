import * as React from 'react';
import Card from '@mui/material/Card';
import { CardContent, CardActions, ImageListItem, Stack, Typography, Avatar } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import { checkRestaurantStatus, checkTimings, openstatus } from './check';
import useDispatch from 'components/common/dispatchHook'
import { saveCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/actions';
import InfoIcon from '@mui/icons-material/Info';
import { getDeliveryServiceType } from 'containers/DashBoard/reducer';

export default function ImgMediaCard({ router, isDetailPage, data, view = false }) {
    const [dispatchAction, getStateData] = useDispatch();
    const status = checkRestaurantStatus(data);
    const deliveryServiceType = getStateData(getDeliveryServiceType);

    return (
        <Card sx={{
            minHeight: view ? 50 : 135,
            maxWidth: '98%',
            display: 'flex',
            flexDirection: view ? "column" : "row",
            marginBottom: '2%',
            boxShadow: 0, opacity: (isDetailPage || status?.status) ? 1 : 0.3,
            padding: view && '1%',
        }}


            onClick={() => {
                !isDetailPage && dispatchAction(saveCurrentRestaurant(data));
                !isDetailPage && router && router(data?.id)
            }}>
            {/* {isDetailPage &&   <InfoIcon sx={{ color: "green" }} style={{position: "absolute", right: 20}} />} */}

            {!isDetailPage && <ImageListItem
                sx={{ alignSelf: view && "center" }}
            >
                {view ?
                    <Avatar
                        sx={{ width: 85, height: 85, align: "center" }}
                        src={data?.imgUrl}
                        alt="loading...."
                    />
                    :
                    <img
                        src={data?.imgUrl}
                        alt="loading...."
                        style={{ width: 135, borderRadius: '15px', maxHeight: 135 }}
                        loading="lazy"
                    />
                }

                {/* <ImageListItemBar
                                        title="{item}"
                                        subtitle="Best for Veg & Non-veg"
                                    /> */}
            </ImageListItem>}


            {view ?
                <CardContent sx={{ paddingRight: 0, padding: '0 !important', marginBottom: '0 !important' }}>
                    <Typography gutterBottom
                        sx={{ width: 85, height: 85 }}
                        style={{
                            fontWeight: 700,
                            paddingBottom: '0 !important',
                            paddingTop: '1%',
                            marginBottom: '0 !important',
                            fontSize: 10, height: 'auto',
                            textAlign: "center"
                        }}
                        component="div">
                        {data?.name}
                    </Typography>
                    <Stack direction='row'  >

                        {data?.reviews > 0 ?
                            <Typography gutterBottom
                                sx={{ width: 85, height: 85 }}
                                style={{
                                    fontWeight: 700,
                                    paddingBottom: '0 !important',
                                    paddingTop: '1%',
                                    marginBottom: '0 !important',
                                    fontSize: 10, height: 'auto',
                                    textAlign: "center"
                                }}
                                component="div">
                                <StarsIcon sx={{ color: "green", fontSize: 10 }} />{((data.avgReviews || 0) / data.reviews).toFixed(
                                    1,
                                )}({data?.reviews})
                            </Typography>
                            :
                            <Typography gutterBottom
                                sx={{ width: 85, height: 85 }}
                                style={{
                                    fontWeight: 700,
                                    paddingBottom: '0 !important',
                                    paddingTop: '1%',
                                    marginBottom: '0 !important',
                                    fontSize: 10, height: 'auto',
                                    textAlign: "center"
                                }}
                                component="div">
                                <StarsIcon sx={{ color: "green", fontSize: 10 }} /> New()
                            </Typography>
                        }
                    </Stack>
                </CardContent>
                :
                <CardContent sx={{ paddingRight: 0, paddingBottom: '0 !important', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography
                        onClick={() => isDetailPage && router && router('/firmDetails')}
                        gutterBottom variant={isDetailPage ? "h5" : 'p'}
                        style={{ fontWeight: 700, marginBottom: '-2px' }}
                        component="div">
                        {data?.name}
                    </Typography>
                    <Typography style={{ fontSize: '14px' }} variant="span" color="text.secondary">
                        {isDetailPage ? data?.description : data?.category}
                    </Typography>
                    <Typography style={{ fontSize: '12px' }} variant="span" color="text.secondary">
                                    Minmum order : INR {data?.minOrder ? data?.minOrder : "NA"}/-
                                </Typography>
                    {isDetailPage &&
                        <CardActions sx={{ width: isDetailPage ? '120%' : '102%', display: 'flex', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: 0 }}>
                            <Stack direction='row' sx={{ width: "95%", marginRight: '2%', alignItems: 'center', marginTop: isDetailPage ? '5px' : '-9px', marginLeft: '-20px', marginBottom: '0' }}>
                                <LocationOnIcon sx={{ color: "green" }} />
                                <Typography style={{ fontSize: '12px' }} variant="span" color="text.secondary">
                                    {data?.address}
                                </Typography>
                            </Stack>
                           
                        </CardActions>

                    }
                    <CardActions sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px', paddingBottom: 0 }}>
                        <Stack
                            onClick={() => isDetailPage && router && router('/restaurantReviews')}
                            direction='row'
                            sx={{ marginRight: '2%', alignItems: 'center', marginTop: isDetailPage ? '5px' : '-9px', marginLeft: '-20px', marginBottom: isDetailPage ? '10px' : '0' }}>
                            <StarsIcon sx={{ color: "green" }} />
                            {data?.reviews > 0 ? <span style={{ fontSize: '12px' }}>{((data.avgReviews || 0) / data.reviews).toFixed(
                                1,
                            )}({data?.reviews})
                                {isDetailPage && <span style={{ fontSize: isDetailPage ? "13px" : '12px' }} >reviews</span>}
                            </span>
                                : <span>New()</span>
                            }
                        </Stack>
                        <span style={{ fontSize: isDetailPage ? "12px" : '10px', marginTop: '-5px', width: '110px' }}>{data?.availableTimings?.toUpperCase()}</span>
                    </CardActions>
                    {isDetailPage ?
                        <div style={{
                            display: 'flex',
                            color: !checkTimings(deliveryServiceType?.location_services?.availableTimings || deliveryServiceType?.availableTimings) ? "red" : status?.status ? "green" : "red"
                        }}>
                            <DeliveryDiningOutlinedIcon /> {
                                checkTimings(deliveryServiceType?.location_services?.availableTimings || deliveryServiceType?.availableTimings) ?
                                status?.message
                                :
                                <span  >Delivery service is closed</span>

                            }
                        </div>
                        :
                        <span />
                        //  <span style={{ fontSize: '13px', color: 'red' }}>10% off on min order of 500/-</span>
                    }
                </CardContent>
            }

        </Card>
    );
}