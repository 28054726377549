import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import useDispatch from 'components/common/dispatchHook'
import { DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom'
// import  {version } from 'package.json';
import { getVersion } from 'containers/LoginPage/reducer';

export default function UpdateMessage({ }) {
    const [dispatchAction, getStateData] = useDispatch();
    const currentVersion = getStateData(getVersion)
    const [open, setOpen] = React.useState(true);

    function handleClose() {
        setOpen(false)
    }

    React.useEffect(() =>{
        // console.log(currentVersion.version,'jjjjjjjjj')
    },[currentVersion])

    return (
        <div>
            <Dialog open={false} fullWidth >
                <DialogTitle id="alert-dialog-title" textAlign={"center"} >
                    UPDATE AVAILABLE!!
                </DialogTitle>
                <DialogContentText sx={{ padding: "3%", textAlign: "center" }} >
                    New Version Available
                </DialogContentText>
                <DialogContentText sx={{ padding: "3%", textAlign: "center" }} >
                    Please, Update the app to enjoy new features
                </DialogContentText>
                <DialogActions sx={{ flexDirection: "row", justifyContent: "space-between" }} >
                    {/* <Button onClick={handleClose} size="large" color='error' >NO</Button> */}
                    <DialogContentText />
                    <Button size="large" color='success' >UPDATE</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}