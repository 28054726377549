import { put, takeEvery, all, call,select } from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT} from 'restClient';
import {getCurrentMaincategory} from './reducer';

function* _get_groceries({ payload }) {
  try {
    const response = yield call(GET, {
      url: '/grocery/fetchAll',
      params: payload,
    });
    yield put({
      type: types.GET_GROCERIES_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_GROCERIES_FAILURE, payload: error });
  }
}
function* _get_groceries_maincat({ payload }) {
  try {
    const response = yield call(GET, {
      url: '/grocery/groceryMainCategory',
      params: payload,
    });
    yield put({
      type: types.GET_GROCERY_MAINCAT_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_GROCERY_MAINCAT_FAILURE, payload: error });
  }
}
function* _get_groceries_subcat({ payload }) {
  try {
    const response = yield call(GET, {
      url: '/grocery/groceryCategory',
      params: payload,
    });
    yield put({
      type: types.GET_GROCERY_SUBCAT_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_GROCERY_SUBCAT_FAILURE, payload: error });
  }
}
function* _update_grocery({ payload }) {
  const mcat = yield select(getCurrentMaincategory);
  try {
    yield call(PUT, {
      url: '/grocery/update',
      requestBody: payload,
    });

    yield put({ type: types.UPDATE_GROCERY_SUCCESS, payload });
    yield put({type: types.GET_GROCERY_SUBCAT, payload: {mainCategory: mcat }});
    // yield call(showToast, 'Grocery info updated');

  } catch (error) {
    yield put({ type: types.UPDATE_GROCERY_FAILURE, payload: error });
    // yield call(showToast, 'Update grocery failed');
  }
}


const _get_groceries_watcher = takeEvery(types.GET_GROCERIES, _get_groceries);
const _get_groceries_maincat_watcher = takeEvery(types.GET_GROCERY_MAINCAT, _get_groceries_maincat);
const _get_groceries_subcat_watcher = takeEvery(types.GET_GROCERY_SUBCAT, _get_groceries_subcat);
const _update_grocery_watcher = takeEvery(types.UPDATE_GROCERY, _update_grocery);

export default function* mainSaga() {
  yield all([_get_groceries_watcher]);
  yield all([_get_groceries_maincat_watcher]);
  yield all([_get_groceries_subcat_watcher]);
  yield all([_update_grocery_watcher]);
}
