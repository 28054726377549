export const LIGHT = {
  name: "light",
  code: "light",
};
export const DARK = {
  name: "dark",
  code: "dark",
};

export const lightTheme = {
  body: '#ffffff',
  header: '#FAFAFA',
  text: 'black',
  toggleBorder: '#FFF',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  form: 'aliceblue',
  primary: '#F78F35',
  secondary: '#500A0A',
}

export const darkTheme = {
  body: '#121214',
  header: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
  form: '#363537',
  primary: '#F78F35',
  secondary: '#500A0A',
}