import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import {
    BottomNavigation,
    BottomNavigationAction,
    Paper,
} from '@mui/material';
import HistoryTwoToneIcon from '@mui/icons-material/HistoryTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import useDispatch from 'components/common/dispatchHook'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getLoggedInUser } from 'containers/LoginPage/reducer';
import HomeIcon from '@mui/icons-material/Home';

const routes = {
    '/dashboard': 0,
    '/myrestaurantpage': 1,
    '/restaurantOrders': 2,
    '/userOrders': 2,
    '/settings': 3
}

export default function BottomNavigationComponent() {
    const loc = useLocation();
    const [dispatchAction, getStateData] = useDispatch();
    const loggedInUser = getStateData(getLoggedInUser);
    const [value, setValue] = React.useState(routes[loc.pathname] || 0 );
    const router = useNavigate();
    return (
                <BottomNavigation
                    showLabels
                    value={value}
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0}}
                >
                    <BottomNavigationAction
                    onClick={(event, newValue) => {
                        setValue(newValue);
                        router("/dashboard")
                    }}
                    id="/dashboard" defaultChecked label="Home" icon={<HomeIcon />} />
                    {loggedInUser?.role === "OWNER" && < BottomNavigationAction
                    onClick={(event, newValue) => {
                        setValue(newValue);
                        router("/myrestaurantpage")
                    }}
                    id="/myrestaurantpage" label="Restaurant" icon={<AccountBalanceIcon />} />}
                    {loggedInUser?.role === "OWNER" && < BottomNavigationAction
                    onClick={(event, newValue) => {
                        setValue(newValue);
                        router("/restaurantOrders")
                    }}
                    id="/restaurantOrders" label="Orders" icon={<DeliveryDiningIcon />} />}
                    {loggedInUser?.role !== "OWNER" && <BottomNavigationAction
                    onClick={(event, newValue) => {
                        setValue(newValue);
                        router("/userOrders")
                    }}
                    id="/userOrders" label="My Orders" icon={<HistoryTwoToneIcon />} />}
                    <BottomNavigationAction
                    onClick={(event, newValue) => {
                        setValue(newValue);
                        router("/settings")
                    }}
                    id="/settings" label="Profile" icon={<AccountCircleTwoToneIcon />} />
                </BottomNavigation>
    );
};
