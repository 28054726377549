import React, { useState } from 'react';
import {
    TextField,
    Button,
    Stack,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Backdrop,
    CircularProgress,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Checkbox
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { verifyPhoneNumber, saveAccountDetails } from 'containers/Registration/actions';
import { loginUser, changePassword } from "containers/LoginPage/actions";
import { getDeviceToken } from "containers/LoginPage/reducer";
import { formControl, forgotPassword, forgotLink } from './styles';
import { useNavigate } from 'react-router-dom'
import ForgotPassword from './forgotPassword';
import { handleSendCode } from 'utils/firbase-config';
import useNotification from 'components/common/notificationHook';
import { Box } from '@mui/system';
import PDFViewerComponent from 'components/common/pdf';




const headers = ['Welcome', 'Login', 'OTP', 'Register', 'Reset Password']

const Login = ({ setIsForgotPassword }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(undefined);
    const [firebaseContext, setFirebaseContext] = useState()
    const [user, setUser] = useState({ role: 'USER' })
    const [isLoading, setLoading] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isLogin, setIsLogin] = useState(0);
    const [resetPass, setResetPass] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [pdfView, setPdfView] = useState(false);
    const [pno, setPno] = useState()
    const router = useNavigate();
    const dispatch = useDispatch()
    const deviceToken = useSelector(getDeviceToken);
    const [setMessage] = useNotification();

    const verifyNumber = async () => {
        try {
            if (isLogin === 4) {
                dispatch(changePassword({ username: pno, password, callback: () => { setIsLogin(0) } }))
                return
            }
            setLoading(true)
            const response = await (new Promise((resolve, reject) => {
                dispatch(verifyPhoneNumber({ pno, resolve, reject }))
            }))
            const context = await handleSendCode(pno);
            setMessage({ message: 'Enter OTP recieved on your phone' })
            setFirebaseContext(context)
            setLoading(false)
            setIsLogin(2)
        } catch (error) {
            // console.log(error)
            setLoading(false)
            setMessage({ message: 'Enter password to Login', type: '' })
            setIsLogin(1)
        }
    }

    const registerUser = () => {
        try {
            setLoading(true)
            dispatch(saveAccountDetails({
                cred: {
                    name: user.name,
                    email: user.email || '',
                    pno: pno,
                    role: ['USER', 'OWNER'].includes(user.role) ? user.role : 'USER',
                    password,
                    deviceToken: { token: deviceToken.token || "token", os: deviceToken.os || 'web' },
                }, router
            }))
            setLoading(false)
        } catch (error) {
            setMessage({ message: 'Error while registering, please try again', type: 'error' })
        }
    }

    const PasswordField = ({ title = "Password" }) => (
        <FormControl sx={formControl} variant="outlined" style={{ background: 'rgb(255 255 255 / 52%)', marginLeft: '6%' }}>
            <InputLabel style={{ fontWeight: 700, fontSize: '20px' }} htmlFor="outlined-adornment-password">{title}</InputLabel>
            {showPassword ? <OutlinedInput
                id="outlined-adornment-password"
                type='text'
                value={password}
                autoFocus={focus}
                onChange={(e) => {
                    setPassword(e.target.value);
                    setFocus(true);
                }
                }
                placeholder='Enter your password'
                startAdornment={<InputAdornment position="end"><IconButton
                    aria-label="toggle password visibility"
                >
                    <LockIcon />
                </IconButton></InputAdornment>}
                endAdornment={
                    <InputAdornment position="end" onClick={(e) => {
                        setShowPassword(false);
                    }
                    }>
                        <IconButton
                            type='button'
                            aria-label="toggle password visibility"

                            edge="end"
                        >
                            <VisibilityOff />
                        </IconButton>
                    </InputAdornment>
                }
                label="Password"
            /> : <OutlinedInput
                id="outlined-adornment-password"
                type='password'
                autoFocus={focus}
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                    setFocus(true);
                }
                }
                placeholder='Enter your password'
                startAdornment={<InputAdornment position="end"><IconButton
                    aria-label="toggle password visibility"
                >
                    <LockIcon />
                </IconButton></InputAdornment>}
                endAdornment={
                    <InputAdornment position="end" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowPassword(true);
                    }
                    }>
                        <IconButton
                            type='button'
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            <Visibility />
                        </IconButton>
                    </InputAdornment>
                }
                label="Password"
            />}
        </FormControl>)

    return (
        <div>
            <div id="re-captch"></div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography style={{ textAlign: 'center', fontWeight: 700 }} gutterBottom variant="h4" component="div">
                {headers[isLogin] || ''}
            </Typography>
            {isLogin === 1 &&
                <Box sx={{ textAlign: "center" }} >
                    <Button
                        style={{ textAlign: 'center', width: "80%", }}
                        id="pno"
                        variant="contained" color="success"
                        onClick={() => setIsLogin(0)} >Phone no : {pno || ""}</Button>
                </Box>
            }
            {isLogin === 0 && <TextField
                required
                style={{ width: '90%', background: 'rgb(255 255 255 / 52%)', color: 'red', fontWeight: '700' }}
                id="outlined-required"
                label={<span style={{ color: 'black', fontWeight: '700' }}>Phone Number</span>}
                type="tel"
                onChange={(e, val) => { setPno(e.target.value) }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">+91 </InputAdornment>,
                }}
                onInput={(e) => {
                    e.target.value = e.target.value && Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) || ''
                }}
                inputProps={{
                    maxLength: 10,
                }}
            />}
            {/* If Phone Number exists, password will be enabled to Login */}
            {isLogin === 1 && <PasswordField />}
            {isLogin === 3 && <>
                <TextField
                    required
                    style={{ width: '90%', background: 'rgb(255 255 255 / 52%)', color: 'red', fontWeight: '700' }}
                    id="outlined-required"
                    label={<span style={{ color: 'black', fontWeight: '700' }}>Full Name</span>}
                    type="text"
                    onChange={(e, val) => {
                        setUser({ ...user, name: e.target.value });
                        setFocus(false)
                    }}
                />
                <TextField
                    style={{ width: '90%', background: 'rgb(255 255 255 / 52%)', color: 'red', fontWeight: '700' }}
                    id="outlined-required"
                    label={<span style={{ color: 'black', fontWeight: '700', display: 'flex' }}>Email
                        <p style={{ fontSize: '14px', marginTop: '0px', marginLeft: '7px' }}>(optional)</p></span>}
                    type="text"
                    onChange={(e, val) => {
                        setUser({ ...user, email: e.target.value });
                        setFocus(false);
                    }}
                />
                <PasswordField title='Enter New Password' />
                <FormControl fullWidth  >
                    <FormLabel style={{
                        marginLeft: '5%',
                        fontSize: '18px',
                        fontWeight: 700,
                        background: 'lightgray',
                        color: 'black',
                        width: '90%',
                        paddingLeft: '10px'
                    }} id="demo-radio-buttons-group-label">I'm a</FormLabel>
                    <RadioGroup
                        style={{ width: '90%', alignSelf: 'center', background: 'lightgray', paddingLeft: '10px' }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="USER"
                        name="radio-buttons-group"
                        onChange={(e) => {
                            setUser({ ...user, role: e.target.value });
                            setFocus(false)
                        }}
                        title="I'm a"
                    >
                        <FormControlLabel value="USER" control={<Radio />} label="User, trying to order food" />
                        <FormControlLabel value="OWNER" control={<Radio />} label="Restaurant Owner, looking for tie-up" />
                    </RadioGroup>
                    <Stack flexDirection={"row"} padding="2%" >
                        <FormControlLabel
                            control={<Checkbox value={termsChecked}
                                onChange={() => setTermsChecked(!termsChecked)} />}
                            label="" />
                        <Button variant='text' color='inherit'
                            onClick={() => setPdfView(true)}
                        >
                            I accept terms and conditions
                        </Button>
                    </Stack>
                </FormControl>
                <PDFViewerComponent status={pdfView}
                    pdfUrl={
                        'https://firebasestorage.googleapis.com/v0/b/step-counter-999fe.appspot.com/o/images%2Fpublic%2Fpdf%2FTerms%20And%20Conditions.pdf?alt=media&token=ff61794a-c5e7-47b0-94b5-9b72197f2dc1'
                    }
                    handleClose={() => setPdfView(false)} />

            </>
            }
            {isLogin !== 2 && <Stack style={{ marginTop: '3%', alignItems: 'center' }}>
                {isLogin === 1 &&
                    <Button
                        style={{ width: '50%' }}
                        id="sign-in"
                        onClick={() => {
                            setLoading(true)
                            dispatch(loginUser({
                                cred: {
                                    username: pno,
                                    password: password,
                                    deviceToken: { token: deviceToken.token || "token", os: deviceToken.os || 'web' },
                                },
                                router
                            }
                            ));
                            setTimeout(() => setLoading(false), 300)
                        }}
                        variant="contained" color="custom">Login</Button>
                }
                {isLogin === 4 && <>
                    <PasswordField title='Enter New Password' />
                </>
                }
                {(isLogin === 0 || isLogin === 4) && <Button
                    disabled={!pno || (pno + "")?.length < 10 || (pno + "")?.length > 10}
                    onClick={() => verifyNumber()} style={{ width: '50%' }}
                    variant="contained"
                    color="custom">Proceed</Button>}
                {isLogin === 3 && <Button
                    onClick={() => registerUser()} style={{ width: '50%', marginBottom: '20px' }}
                    variant="contained"
                    disabled={!termsChecked}
                    color="custom">Register</Button>}
            </Stack>}
            {isLogin === 1 && <span style={forgotPassword}>Forgot password ?
                <span onClick={() => {
                    setIsLogin(2);
                    setResetPass(true)
                }} style={forgotLink}> reset here </span>
            </span>}
            {isLogin === 2 &&
                <ForgotPassword
                    setLoading={setLoading}
                    data={!resetPass}
                    pno={pno}
                    setPno={setPno}
                    context={firebaseContext}
                    setFirebaseContext={setFirebaseContext}
                    setIsLogin={setIsLogin} />}

        </div>
    )
}

export default Login;