import {put, takeEvery, all, call} from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT} from 'restClient';
// import {navigate} from '~/utils/navigationService';
import {showToast} from 'utils/notification';

function* _add_order({payload}) {
  try {
    const response = yield call(POST, {
      url: '/restaurant/order-food',
      requestBody: payload,
    });
    yield put({type: types.ADD_ORDER_SUCCESS, payload: response.data});
    yield call(showToast, 'Order food success');
    // navigate('ThankNote');
  } catch (error) {
    yield put({type: types.ADD_ORDER_FAILURE, payload: error});
    yield call(showToast, 'Failed to place Order');
  }
}

function* _add_new_address({payload}) {
  try {
    const response = yield call(POST, {
      url: '/user/address',
      requestBody: payload,
    });
    yield put({type: types.GET_DELIVERY_ADDRESS, payload: {}});
    yield put({type: types.ADD_NEW_ADDRESS_SUCCESS, payload: response.data});
    yield call(showToast, 'Address added successfully');
  } catch (error) {
    yield put({type: types.ADD_NEW_ADDRESS_FAILURE, payload: error});
    yield call(showToast, 'Failed to Add address');
    yield call(showToast, error.response.data.message);
  }
}
function* _update_address({payload}) {
  try {
    yield call(PUT, {
      url: '/user/address/' + payload.addressId,
      requestBody: payload.state,
    });
    yield put({type: types.GET_DELIVERY_ADDRESS, payload: {}});
    yield put({type: types.UPDATE_ADDRESS_SUCCESS, payload});
  } catch (error) {
    yield put({type: types.UPDATE_ADDRESS_FAILURE, payload: error});
    yield call(showToast, 'Failed to Update address');
  }
}

function* _get_delivery_address({payload}) {
  try {
    const response = yield call(GET, {url: '/user/address'});
    yield put({
      type: types.GET_DELIVERY_ADDRESS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({type: types.GET_DELIVERY_ADDRESS_FAILURE, payload: error});
  }
}

function* _get_user_orders({payload}) {
  try {
    const response = yield call(GET, {
      url: '/restaurant/fetch-orders',
      params: payload,
    });
    yield put({
      type: types.GET_USER_ORDERS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({type: types.GET_USER_ORDERS_FAILURE, payload: error});
  }
}

function* _get_orders_count({payload}) {
  try {
    const response = yield call(GET, {
      url: '/restaurant/orders-count',
      params: payload,
    });
    yield put({
      type: types.GET_ORDERS_COUNT_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({type: types.GET_ORDERS_COUNT_FAILURE, payload: error});
  }
}

const _add_order_watcher = takeEvery(types.ADD_ORDER, _add_order);
const _add_new_address_watcher = takeEvery(
  types.ADD_NEW_ADDRESS,
  _add_new_address,
);
const _update_address_watcher = takeEvery(
  types.UPDATE_ADDRESS,
  _update_address,
);
const _get_delivery_address_watcher = takeEvery(
  types.GET_DELIVERY_ADDRESS,
  _get_delivery_address,
);
const _get_user_orders_watcher = takeEvery(
  types.GET_USER_ORDERS,
  _get_user_orders,
);
const _get_orders_count_watcher = takeEvery(
  types.GET_ORDERS_COUNT,
  _get_orders_count,
);

export default function* mainSaga() {
  yield all([
    _add_order_watcher,
    _add_new_address_watcher,
    _update_address_watcher,
    _get_delivery_address_watcher,
    _get_user_orders_watcher,
    _get_orders_count_watcher,
  ]);
}
