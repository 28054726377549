import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, Typography, Button } from '@mui/material';
import { handleSendCode, handleVerifyCode } from 'utils/firbase-config';
import useNotification from 'components/common/notificationHook';

export default function ForgotPassword({ data, context, pno, setPno, setIsLogin, setLoading, setFirebaseContext }) {
    const [isOtp, setIsOtp] = useState(data);
    const [setMessage] = useNotification();
    let otp = ''

    useEffect(() => document.getElementById('1')?.focus(), [])

    const handleChange = (e) => {
        const id = parseInt(e.target.id)
        const isDelete = e.nativeEvent.inputType === 'deleteContentBackward';
        if ((id > 6 && !isDelete) || (id - 1 < 1 && isDelete)) return;
        if (isDelete) {
            otp = otp.slice(0, otp?.length - 1)
            return document.getElementById(id - 1)?.focus();
        }
        otp += e.target.value;
        document.getElementById(id + 1)?.focus()
    }

    const handleSendOtp = async () => {
        try {
            setLoading(true)
            const result = await handleSendCode(pno)
            setMessage({ message: 'Enter OTP recieved on your phone' })
            setFirebaseContext(result)
            setIsOtp(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessage({ message: 'Error while sending OTP', type: 'error' })
            // console.log(error)
        }
    }

    const verifyOtp = async () => {
        try {
            setLoading(true)
            const res = await handleVerifyCode(context, otp)
            setMessage({ message: 'OTP verification success', type: 'success' })
            setIsLogin(data ? 3 : 4)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setMessage({ message: 'Invalid/Incorrect OTP', type: 'error' })
        }
    }

    return (
        <div>
            <div id="re-captch"></div>
            <Typography variant="h5" sx={{ my: 3 }} style={{}}>
                {isOtp ? 'Enter OTP' : 'Enter your Registered number'}
            </Typography>
            {
                isOtp
                    ? <><div style={{ textAlign: 'center', marginBottom: '5%' }}>
                        {
                            [1, 2, 3, 4, 5, 6].map(item =>
                                <TextField
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    type="tel"
                                    key={item}
                                    onChange={(e) => handleChange(e)}
                                    style={{ width: '30px', background: 'rgb(230 234 228 / 60%)', margin: 5, padding: 0, paddingLeft: '10px' }}
                                    id={item + ""} variant="standard" />
                            )
                        }
                    </div>
                        <Button onClick={() => verifyOtp()} style={{ marginLeft: '5%' }} variant="contained" color="custom">Verify OTP</Button>
                    </>
                    : <> <TextField

                        required
                        style={{ width: '90%', background: 'rgb(230 234 228 / 60%)' }}
                        id="outlined-required"
                        onChange={(e) => setPno(e.target.value)}
                        label={<span style={{ color: 'black', fontWeight: '700' }}>Phone Number</span>}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91 </InputAdornment>,
                        }}
                        inputProps={{
                            maxLength: 10,
                        }}
                    />
                        <Button onClick={() => handleSendOtp()} style={{ marginLeft: '5%' }} variant="contained" color="custom">Send OTP</Button>
                        <Button onClick={() => setIsLogin(1) } style={{ marginLeft: '5%' }} variant="outlined" color="primary">Back</Button>
                    </>
            }
        </div>
    )
}