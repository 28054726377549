import {
  GET_USER,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  reducer,
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  RESTAURANT_ADDED,
  RESTAURANT_UPDATED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SAVE_DEVICE_TOKEN,
  SAVE_NOTIFICATION,
  SAVE_PNO,
  TRIGGER_FLAGS,
  TRIGGER_NATIVE_BACK
} from './constants';

const initialState = {
  loading: false,
  userList: [],
  user: {},
  version: {},
  deviceToken: {},
  notification: {},
  pno: {},
  flags: false,
  backPress: false
};

export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };
    case GET_USER:
    case LOGIN_USER:
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS: {
      return {
        ...state,
        userList: payload,
        loading: false,
      };
    }
    case RESTAURANT_ADDED: {
      const user = JSON.parse(JSON.stringify(state.user));
      user.hotels = [payload];
      return {
        ...state,
        user,
      };
    }
    case RESTAURANT_UPDATED: {
      const user = JSON.parse(JSON.stringify(state.user));
      let index = user.hotels?.findIndex((hot) => payload?.id === hot?.id)
      user.hotels[index] = { ...user.hotels[index], ...payload };
      return {
        ...state,
        user,
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: payload.user,
        version: payload.version,
      };
    }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_DEVICE_TOKEN:
      return { ...state, deviceToken: payload };
    case TRIGGER_NATIVE_BACK:
      return { ...state, backPress: payload };
    case SAVE_NOTIFICATION:
      return { ...state, notification: payload };

    case SAVE_PNO:
      return { ...state, pno: payload };

    case TRIGGER_FLAGS:
      return { ...state, flags: payload };

    default:
      return state;
  }
};

export const userList = (state) => {
  return state[reducer].userList;
};

export const isLoading = (state) => {
  return state[reducer].loading;
};
export const getLoggedInUser = (state) => state[reducer].user;
export const getVersion = (state) => state[reducer].version;
export const getDeviceToken = (state) => state[reducer].deviceToken;
export const getNotification = (state) => state[reducer].notification;
export const getPno = (state) => state[reducer].pno;
export const getTriggeredFlag = (state) => state[reducer].flags;
export const getNativeBackPress = (state) => state[reducer].backPress;
export { reducer };
