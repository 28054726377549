import React from 'react';
import {Stack, Skeleton} from '@mui/material';

export default function SkeletonComp(){
    return (
        <>
        <Stack direction="row" spacing={40}>
        <Skeleton variant="text" width={40} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="circular" width={40} height={40} />

        </Stack>
        <Skeleton variant="rectangular" height={200} />
        <Stack direction="row" spacing={4} style={{marginTop: '10%'}}>
        <Skeleton variant="rectangular" width={90} height={50} />
        <Skeleton variant="rectangular" width={90} height={50} />
        <Skeleton variant="rectangular" width={90} height={50} />
        <Skeleton variant="rectangular" width={90} height={50} />

        </Stack>
        <Skeleton style={{marginTop: '10%'}} variant="rectangular" height={40} />
        <Skeleton style={{marginTop: '10%'}} variant="rectangular" height={90} />
        <Skeleton style={{marginTop: '10%'}} variant="rectangular" height={70} />
        </>
    )
}