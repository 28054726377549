import {put, takeEvery, all, call} from 'redux-saga/effects';
import * as types from './constants';
import * as notificationtTypes from 'containers/DashBoard/constants';
import {showToast} from 'utils/notification';
import { GET, POST,setDefaultHeaders, PUT} from 'restClient';
function* _get_user({payload}) {
  try {
    const response = yield call(GET, {
      url: '/user/fetchById/b11cbe40-abea-11eb-9d51-678cd648249c',
    });
    yield put({type: types.GET_USER_SUCCESS, payload: response.data.message});
  } catch (error) {
    yield put({type: types.GET_USER_FAILURE, payload: error});
  }
}

function* _login_user({payload}) {
  try {
    const response = yield call(POST, {
      url: '/auth/login',
      requestBody: payload.cred,
    });
    setDefaultHeaders('x-access-token', response?.data?.message?.token);
    localStorage.setItem('x-access-token', response?.data?.message?.token);
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'Login Success', type: 'success'}});
    yield put({type: types.LOGIN_USER_SUCCESS, payload: response.data.message});
    payload?.router && payload?.router('/dashboard');
  } catch (error) {
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'Inavalid username/password', type: 'error'}});
    yield put({type: types.LOGIN_USER_FAILURE, payload: error});
  }
}

function* _change_password({payload}) {
  try {
    const {callback} = payload;
    delete payload?.callback;
    const response = yield call(POST, {
      url: '/auth/forgotPassword',
      requestBody: payload,
    });
    yield put({
      type: types.CHANGE_PASSWORD_SUCCESS,
      payload: response.data,
    });
    yield put({type: types.TRIGGER_FLAGS, payload: true});
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'password updated, please login', type: 'success'}});
    callback && callback()
  } catch (error) {
    yield put({type: notificationtTypes.SHOW_NOTIFICATION, payload: {message: 'Oops... password change unsuccessful, Please try again', type: 'error'}});
    yield put({type: types.CHANGE_PASSWORD_FAILURE, payload: error});
  }
}

const _get_user_watcher = takeEvery(types.GET_USER, _get_user);
const _login_user_watcher = takeEvery(types.LOGIN_USER, _login_user);
const _change_password_watcher = takeEvery(
  types.CHANGE_PASSWORD,
  _change_password,
);

export default function* mainSaga() {
  yield all([_get_user_watcher, _login_user_watcher, _change_password_watcher]);
}
