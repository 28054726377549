export const reducer = 'Dishes';

export const UPDATE_DISH = reducer + '/UPDATE_DISH';
export const UPDATE_DISH_SUCCESS = reducer + '/UPDATE_DISH_SUCCESS';
export const UPDATE_DISH_FAILURE = reducer + '/UPDATE_DISH_FAILURE';

export const GET_DISHES = reducer + '/GET_DISHES';
export const GET_DISHES_SUCCESS = reducer + '/GET_DISHES_SUCCESS';
export const GET_DISHES_FAILURE = reducer + '/GET_DISHES_FAILURE';

export const GET_DISHES_CATEGORY = reducer + '/GET_DISHES_CATEGORY';
export const GET_DISHES_CATEGORY_SUCCESS =
  reducer + '/GET_DISHES_CATEGORY_SUCCESS';
export const GET_DISHES_CATEGORY_FAILURE =
  reducer + '/GET_DISHES_CATEGORY_FAILURE';

export const SAVE_CURRENT_ORDER = reducer + '/SAVE_CURRENT_ORDER';

export const TRIGGER_ADD_ITEM = reducer + '/TRIGGER_ADD_ITEM';

export const TRIGGER_VIEW_CART = reducer + '/TRIGGER_VIEW_CART';

export const UPDATE_DISH_ARRAY = reducer + '/UPDATE_DISH_ARRAY';
export const UPDATE_DISH_ARRAY_SUCCESS = reducer + '/UPDATE_DISH_ARRAY_SUCCESS';
export const UPDATE_DISH_ARRAY_FAILURE = reducer + '/UPDATE_DISH_ARRAY_FAILURE';

export const DISH_UPDATED = reducer + '/DISH_UPDATED';
export const SAVE_CURRENT_ITEM = reducer + '/SAVE_CURRENT_ITEM';
