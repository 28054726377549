import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Stack, CardHeader, ButtonGroup, Box, Checkbox, FormControlLabel, Chip, Button, CardContent, CircularProgress } from '@mui/material';
import useDispatch from 'components/common/dispatchHook'
import { displayDateTime, text } from './check';
import { getDeliveryAddress } from 'containers/CartPage/actions';
import { getDeliveryAddressList , isLoading } from 'containers/CartPage/reducer';
import LayoutWrapper from './layoutWrapper';
import { useNavigate } from 'react-router-dom';
// import AddAddressForm from './addAddress';
import { updateAddress } from 'containers/CartPage/actions';
import ConfirmModal from 'components/common/confirm-modal';
import Locations from 'components/Dashboard/location';
import { saveCurrentDeliveryAddress } from 'containers/CartPage/actions';

const AddressList = ({ Info, Role = "USER", onSelect, deliveryAddress, isDashboard , title="Saved address"}) => {
    const router = useNavigate();
    const [dispatchAction, getStateData] = useDispatch();
    const addressList = getStateData(getDeliveryAddressList);
    const loading = getStateData(isLoading);
    const [itemData, setItemData] = useState({})
    const [showConfirm, setShowConfirm] = useState(false)
    useEffect(() => {
        dispatchAction(getDeliveryAddress())
    }, [])

    const [form, setForm] = useState(false)

    function handleClose() {
        setForm(false);
    }
    function handleEdit(item) {
        // setItemData(item);
        // setForm(true);
        dispatchAction(saveCurrentDeliveryAddress(item));
        router("/addaddressform")
    }

    return (
        <Box>
            {isDashboard &&
                <>
                    {text(`Nammuru Locations`, '', 15, "center")}
                    <Locations onSelect={onSelect} />
                </>
            }
            {text(title, '', 15, "center")}

            <Button onClick={() => handleEdit({})}>Add New Address</Button>
            {loading ?


                <CircularProgress
                    size={24}
                    sx={{
                        color: "green",
                    }}
                />
                :
            
            addressList?.map((address) => (
            <Card sx={{
                maxWidth: '98%',
                display: 'flex',
                flexDirection: "column",
                margin: '2%',
                boxShadow: 0,
                padding: "2% ",
                paddingTop: "1% !important",
                position: 'relative',
                paddingLeft: '8%'
            }}
                key={address?.id}>
                <CardHeader
                    titleTypographyProps={{ fontSize: 12, textAlign: "left", fontWeight: "700" }}
                    title={`${address?.name} :  ${address?.pno}`}
                    sx={{ padding: 0 }}
                    style={{ position: 'relative' }}
                    subheader={address?.address1 && text(address?.address1, 'text.secondary', 10)}
                    onClick={() => onSelect && onSelect({address: address})}
                    action={
                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button variant={isDashboard ? 'outlined' : 'text'} onClick={() => handleEdit(address)}>Edit</Button>
                            {!isDashboard && <Button
                                onClick={() =>
                                    dispatchAction(
                                        updateAddress({
                                            addressId: address?.id,
                                            state: { delete: true },
                                        }),
                                    )
                                }>Delete</Button>}
                        </ButtonGroup>
                    }
                />
                {onSelect && <FormControlLabel style={{ position: 'absolute', left: 0, top: '30%' }}
                    control={<Checkbox onClick={() => onSelect({address: address})} checked={deliveryAddress.id === address.id} />} label="" />}
                {address?.address2 && text(address?.address2, 'text.secondary', 10)}
                {address?.landMark && text(`Landmark :  ${address?.landMark}`, 'text.secondary', 10)}
                <Stack direction='row' justifyContent="space-between"  >
                    <Stack>
                        {text(`Address type :  ${address?.type}`, 'text.secondary', 10)}
                        {text(`Last updated :  ${displayDateTime(address?.updatedAt)}`, 'text.secondary', 8)}
                    </Stack>
                    {/* <Chip color="primary" clickable label="Show map" size="small" variant="outlined" /> */}
                </Stack>
            </Card>
            ))}
            {/* <AddAddressForm status={form} handleClose={handleClose}
                editData={itemData}
            /> */}
            <ConfirmModal
                // message="Once the order placed, it cannot be cancelled."
                message="Orders once placed cannot be cancelled."
                title="Are you sure?"
                showConfirm={showConfirm}
                setShowConfirm={setShowConfirm}
            />

        </Box>
    );
}
export default LayoutWrapper(AddressList, "My address")