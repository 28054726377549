export const reducer = 'DashBoard';

export const GET_LOCATIONS = reducer + '/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = reducer + '/GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = reducer + '/GET_LOCATIONS_FAILURE';

export const GET_SERVICES = reducer + '/GET_SERVICES';
export const GET_SERVICES_SUCCESS = reducer + '/GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = reducer + '/GET_SERVICES_FAILURE';

export const GET_CAROUSEL = reducer + '/GET_CAROUSEL';
export const GET_CAROUSEL_SUCCESS = reducer + '/GET_CAROUSEL_SUCCESS';
export const GET_CAROUSEL_FAILURE = reducer + '/GET_CAROUSEL_FAILURE';

export const SAVE_CURRENT_LOCATION = reducer + '/SAVE_CURRENT_LOCATION';

export const SAVE_DELIVERY_SERVICE_TYPE = reducer + '/SAVE_DELIVERY_SERVICE_TYPE';
export const SHOW_NOTIFICATION = reducer + '/SHOW_NOTIFICATION';

export const GET_TOP_RESTAURANTS = reducer + '/GET_TOP_RESTAURANTS';
export const GET_TOP_RESTAURANTS_SUCCESS = reducer + '/GET_TOP_RESTAURANTS_SUCCESS';
export const GET_TOP_RESTAURANTS_FAILURE = reducer + '/GET_TOP_RESTAURANTS_FAILURE';
