// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import { getStorage, ref, uploadBytes } from 'firebase/storage';
import 'firebase/compat/app-check';
import config from './consifg'

import { ref, uploadBytes, getDownloadURL, deleteObject, getStorage, uploadBytesResumable } from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = initializeApp(config);
const auth = getAuth(app);

// Initialize Firebase

export const uploadFIle = async (file, val) => {
  const fileRef = ref(storage, `/images/public/grocery/${val.category.type}/${file.name}`);
  const snapShot = await uploadBytes(fileRef, file);
  const url = await getDownloadURL(snapShot.ref);
  if (val.imgurl) {
    try {
      const desertRef = ref(storage, val.imgurl);
      await deleteObject(desertRef)
    } catch (error) {
      // console.log('delete image failed', error);
    }
  }
  return url;
}

export const uploadCarouselImage = async (file, val) => {
  const fileRef = ref(storage, `/images/public/carousel/restaurants/${file.name}`);
  const snapShot = await uploadBytes(fileRef, file);
  const url = await getDownloadURL(snapShot.ref);
  return url
  // if(val.imgurl){
  //     try {
  //         const desertRef = ref(storage, val.imgurl);
  //         await deleteObject(desertRef)
  //     } catch (error) {
  //         console.log('delete image failed', error);            
  //     }
  // }
  // return url;
}


export const validatePhoneNumber = (phone) => {
  const regexp = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/;
  return regexp.test(phone);
};


export const uploadImage = async (file, path = '/', restaurant = '') => {
  try {
    const {
      name,
      uri,
      access = 'public',
    } = file;
    return new Promise((res, rej) => {
      const storage = getStorage(app);
      const imgReference = ref(storage, `/images/${access}/${path}/${restaurant}/${name}`)
        const uploadTask = uploadBytesResumable(imgReference, file);
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            // setProgresspercent(progress);
          },
          (error) => {
            alert(error);
            rej(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              res(downloadURL)
            });
          }
        );
    })
    
  } catch (error) {
    // console.log(error, 'Error while uploading image, please try again');
    return { response: null };
  }
};

export const handleSendCode = (phone, forceResend = false) => {
  const appVerifier = new RecaptchaVerifier('re-captch', {
    'size': 'invisible',
    'callback': (response) => {
    }
  }, auth);
  return new Promise((res, rej) => {
    if (validatePhoneNumber('+91' + phone)) {
      signInWithPhoneNumber(auth, '+91' + phone, appVerifier)
        .then((confirmResult) => {
          res(confirmResult);
        })
        .catch((error) => {
          rej(error.message);
        });
    } else {
      rej('Invalid Phone Number');
    }
  });
};

export const handleVerifyCode = (confirmResult, otp) => {
  return new Promise((res, rej) => {
    // Request for OTP verification
    if (otp?.length === 6) {
      confirmResult
        .confirm(otp)
        .then((user) => {
          res(user);
        })
        .catch((error) => {
          rej(error);
        });
    } else {
      rej({ custom: 'Please enter a 6 digit OTP code.' });
    }
  });
};

