import { put, takeEvery, all, call } from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT } from 'restClient';
import * as notificationtTypes from 'containers/DashBoard/constants';

function* _add_order({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/restaurant/order-food',
      requestBody: payload?.data,
    });
    yield put({ type: types.ADD_ORDER_SUCCESS, payload: response.data });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Order placed successfully', type: 'success' } });
    payload?.router && payload?.router('/thanks');
  } catch (error) {
    yield put({ type: types.ADD_ORDER_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: error?.response?.data?.message || 'Failed to place order', type: 'error' } });
  }
}

function* _add_new_address({ payload }) {
  try {
    const response = yield call(POST, {
      url: '/user/address',
      requestBody: payload,
    });
    yield put({ type: types.GET_DELIVERY_ADDRESS, payload: {} });
    yield put({ type: types.ADD_NEW_ADDRESS_SUCCESS, payload: response.data });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'New address added', type: 'success' } });
  } catch (error) {
    yield put({ type: types.ADD_NEW_ADDRESS_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: error?.response?.data?.message || 'Failed to add new address', type: 'error' } });
  }
}
function* _update_address({ payload }) {
  try {
    yield call(PUT, {
      url: '/user/address/' + payload.addressId,
      requestBody: payload.state,
    });
    yield put({ type: types.GET_DELIVERY_ADDRESS, payload: {} });
    yield put({ type: types.UPDATE_ADDRESS_SUCCESS, payload });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message: 'Address updated', type: 'success' } });

  } catch (error) {
    yield put({ type: types.UPDATE_ADDRESS_FAILURE, payload: error });
    yield put({ type: notificationtTypes.SHOW_NOTIFICATION, payload: { message:  error?.response?.data?.message || 'Failed to update', type: 'error' } });

  }
}

function* _get_delivery_address({ payload }) {
  try {
    const response = yield call(GET, { url: '/user/address' });
    yield put({
      type: types.GET_DELIVERY_ADDRESS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_DELIVERY_ADDRESS_FAILURE, payload: error });
  }
}
function* _get_coupons({ payload }) {
  try {
    const response = yield call(GET, {
      url: '/coupons/getCoupons',
      params: payload
    });
    yield put({
      type: types.GET_COUPONS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: types.GET_COUPONS_FAILURE, payload: error });
  }
}

const _add_order_watcher = takeEvery(types.ADD_ORDER, _add_order);
const _add_new_address_watcher = takeEvery(
  types.ADD_NEW_ADDRESS,
  _add_new_address,
);
const _update_address_watcher = takeEvery(
  types.UPDATE_ADDRESS,
  _update_address,
);
const _get_delivery_address_watcher = takeEvery(
  types.GET_DELIVERY_ADDRESS,
  _get_delivery_address,
);
const _get_coupons_watcher = takeEvery(
  types.GET_COUPONS,
  _get_coupons,
);

export default function* mainSaga() {
  yield all([
    _add_order_watcher,
    _add_new_address_watcher,
    _update_address_watcher,
    _get_delivery_address_watcher,
    _get_coupons_watcher,
  ]);
}
