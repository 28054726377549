import {
  reducer,
  GET_DISHES,
  GET_DISHES_FAILURE,
  GET_DISHES_SUCCESS,
  UPDATE_DISH,
  UPDATE_DISH_FAILURE,
  UPDATE_DISH_SUCCESS,
  GET_DISHES_CATEGORY,
  GET_DISHES_CATEGORY_FAILURE,
  GET_DISHES_CATEGORY_SUCCESS,
  SAVE_CURRENT_ORDER,
  TRIGGER_ADD_ITEM,
  TRIGGER_VIEW_CART,
  UPDATE_DISH_ARRAY,
  UPDATE_DISH_ARRAY_FAILURE,
  UPDATE_DISH_ARRAY_SUCCESS,
  DISH_UPDATED,
  SAVE_CURRENT_ITEM
} from './constants';

const initialState = {
  loading: false,
  dishesList: {},
  dishCategoryList: [],
  currentOrder: [],
  triggerItem: { price: 0, count: 0, items: [] },
  triggerCart: { cart: false, header: '' },
  currentItem: {},
};
4;

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_DISH_SUCCESS:
    case UPDATE_DISH_ARRAY_SUCCESS:
      return { ...state, loading: false };
    case GET_DISHES_FAILURE:
    case UPDATE_DISH_FAILURE:
    case UPDATE_DISH_ARRAY_FAILURE:
    case GET_DISHES_CATEGORY_FAILURE:
      return { ...state, loading: false };

    case GET_DISHES:
    case UPDATE_DISH:
    case UPDATE_DISH_ARRAY:
    case GET_DISHES_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case GET_DISHES_SUCCESS: {
      return {
        ...state,
        dishesList: payload,
        loading: false,
      };
    }
    case GET_DISHES_CATEGORY_SUCCESS: {
      return {
        ...state,
        dishCategoryList: payload,
        loading: false,
      };
    }
    case SAVE_CURRENT_ORDER:
      return { ...state, currentOrder: payload };

    case TRIGGER_ADD_ITEM:
      return { ...state, triggerItem: payload };

    case TRIGGER_VIEW_CART:
      return { ...state, triggerCart: payload };
      case SAVE_CURRENT_ITEM:
        return { ...state, currentItem: payload };
    case DISH_UPDATED: {
      const dishesList = JSON.parse(JSON.stringify(state.dishesList));
      Object?.keys(dishesList)?.map(ite =>
        dishesList[ite]?.map((dish, index) => {
          if (payload?.dishId === dish?.id) {
            if (payload?.state?.delete) dishesList[ite]?.splice(index, 1)
            else dishesList[ite][index] = ({ ...dish, ...payload?.state })
          }
          if (dishesList[ite]?.length === 0) delete dishesList[ite];
        })

      )
      return {
        ...state,
        dishesList,
      };
    }

    default:
      return state;
  }
};

export const getDishesList = (state) => {
  return state[reducer].dishesList;
};
export const getDishCategoryList = (state) => {
  return state[reducer].dishCategoryList;
};
export const getCurrentOrder = (state) => {
  return state[reducer].currentOrder;
};
export const getTriggeredItem = (state) => {
  return state[reducer].triggerItem;
};
export const getTriggeredViewCart = (state) => {
  return state[reducer].triggerCart;
};
export const getCurrentItem = (state) => {
  return state[reducer].currentItem;
};

export const isLoading = (state) => {
  return state[reducer].loading;
};
export const isLoadingDishes = (state) => {
  return state[reducer].loading;
};
export { reducer };
