import { createTheme } from '@mui/material/styles';
import { LIGHT, DARK, lightTheme, darkTheme } from "./colors";
import { TOGGLE_THEME } from "./actionTypes";

const initialState = createTheme({
    palette: {
      mode: 'light',
      light: 'white',
      secondary: {
        main: '#F78F35'
      },
      background: {
        default: "#f7f5f1c2"
      },
      custom: {
        main: '#F78F35',
        light: '#f7f5f1c2',
      }
    },
  });

const ThemeWrapper = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_THEME:
            switch (action.payload) {
                case DARK.name:
                    return createTheme({
                        palette: {
                          mode: 'dark',
                          light: 'white',
                          custom: {
                            main: '#F78F35',
                          }
                        },
                      });
                case LIGHT.name:
                    return initialState;
                default:
                    return state;
            }
        default:
            return state;
    }
};
export default ThemeWrapper
export const reducer = "Theme"

export const getCurrentTheme = state => state[reducer]