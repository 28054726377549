export const box = {
    minHeight: 280,
    '& .MuiTextField-root': { m: 2.5, width: '25ch' },
    border: '0.4px solid grey',
    boxShadow: '1px 0px 8px 2px',
    '&:hover': {
      opacity: [1, 0.8, 0.7],
    },
  };

  export const logo = {
    width: '180px',
    height: '50px',
    top: '-50%',
    position: 'absolute'
};

  export const container = {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999
}

export const formControl = { m: 1, width: '90%' };

export const forgotPassword = { display: 'flex', marginLeft: '10%', marginTop: '3%', width: '100%' };

export const forgotLink = { textDecoration: 'underline', color: 'orange', marginLeft: '1%' };