import {
  reducer,
  ADD_ORDER,
  ADD_ORDER_FAILURE,
  ADD_ORDER_SUCCESS,
  ADD_NEW_ADDRESS,
  ADD_NEW_ADDRESS_FAILURE,
  ADD_NEW_ADDRESS_SUCCESS,
  GET_DELIVERY_ADDRESS,
  GET_DELIVERY_ADDRESS_FAILURE,
  GET_DELIVERY_ADDRESS_SUCCESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_SUCCESS,
  GET_USER_ORDERS,
  GET_USER_ORDERS_FAILURE,
  GET_USER_ORDERS_SUCCESS,
  GET_ORDERS_COUNT,
  GET_ORDERS_COUNT_SUCCESS,
  GET_ORDERS_COUNT_FAILURE
} from './constants';

const initialState = {
  loading: false,
  deliveryAddressList: [],
  userOrderList:[],
  ordersCount: 0,
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ORDER_SUCCESS:
    case ADD_NEW_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
      return { ...state, loading: false };

    case ADD_ORDER_FAILURE:
    case ADD_NEW_ADDRESS_FAILURE:
    case UPDATE_ADDRESS_FAILURE:
    case GET_DELIVERY_ADDRESS_FAILURE:
    case GET_USER_ORDERS_FAILURE:
      case GET_ORDERS_COUNT_FAILURE:
      return { ...state, loading: false };

    case ADD_ORDER:
    case ADD_NEW_ADDRESS:
    case UPDATE_ADDRESS:
    case GET_DELIVERY_ADDRESS:
    // case GET_USER_ORDERS:
      case GET_ORDERS_COUNT:
      return {
        ...state,
        loading: true,
      };

      case GET_USER_ORDERS:
        return {
          ...state,
          loading: true
        }

    case GET_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        deliveryAddressList: payload,
        loading: false,
      };
    }


    case GET_USER_ORDERS_SUCCESS: {
      return {
        ...state,
        userOrderList: payload,
        loading: false,
      };
    }
    case GET_ORDERS_COUNT_SUCCESS: {
      return {
        ...state,
        ordersCount: payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const getDeliveryAddressList = (state) => {
  return state[reducer].deliveryAddressList;
};
export const getUserOrderList = (state) => {
  return state[reducer].userOrderList;
};
export const OrdersCount = (state) => {
  return state[reducer].ordersCount;
};


export const isLoading = (state) => {
  return state[reducer].loading;
};
export { reducer };
