import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AppBar, IconButton, Avatar, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDispatch from 'components/common/dispatchHook'
import { Document, Page, pdfjs } from 'react-pdf';
import Header from './Header';

export default function PDFViewerComponent({ status, handleClose, title = "", pdfUrl = "" }) {
    const [dispatchAction, getStateData] = useDispatch();
    const [numPages, setNumPages] = React.useState(null);
    React.useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);


    return (
        <div style={{position: 'relative'}}>
            <Dialog open={status} onClose={handleClose} fullScreen sx={{zIndex: 9999}} >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, alignSelf: "center" }} variant="h6" component="div">
                            {title}
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}
                    </Toolbar>
                </AppBar>
                <DialogContent>

                    <Document
                        file={pdfUrl}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    // "https://firebasestorage.googleapis.com/v0/b/step-counter-999fe.appspot.com/o/images%2Fpublic%2Fpdf%2FPrivacy%20Policy.pdf?alt=media&token=05adbee0-2100-4c61-809e-c46d0764cd4a"
                    >
                        {/* <Page pageNumber={1} /> */}
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(page => <Page pageNumber={page} />)}
                    </Document>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
