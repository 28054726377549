import {
  GET_USER,
  LOGIN_USER,
  CHANGE_PASSWORD,
  SAVE_DEVICE_TOKEN,
  SAVE_NOTIFICATION,
  SAVE_PNO,
  TRIGGER_FLAGS,
  TRIGGER_NATIVE_BACK,
} from './constants';

export const getUser = (payload) => ({
  type: GET_USER,
  payload,
});

export const loginUser = (payload) => ({
  type: LOGIN_USER,
  payload,
});

export const changePassword = (payload) => ({
  type: CHANGE_PASSWORD,
  payload,
});

export const saveDeviceToken = (payload) => ({
  type: SAVE_DEVICE_TOKEN,
  payload,
});

export const savePno = (payload) => ({
  type: SAVE_PNO,
  payload,
});

export const saveNotification = (payload) => ({
  type: SAVE_NOTIFICATION,
  payload,
});

export const triggerFlags = (payload) => ({
  type: TRIGGER_FLAGS,
  payload,
});

export const handleNativeBackPress = (payload) => ({
  type: TRIGGER_NATIVE_BACK,
  payload,
});