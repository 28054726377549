import {put, takeEvery, all, call, select} from 'redux-saga/effects';
import * as types from './constants';
import { GET, POST, PUT} from 'restClient';
import { activeLocation } from 'containers/DashBoard/reducer';

function* _get_restaurants({payload}) {
  const location = yield select(activeLocation);
  try {
    const {callback} = payload;
    delete payload?.callback
    const response = yield call(GET, {
      url: '/restaurant/fetch-restaurant',
      params: {...payload, locationId: location?.id},
    });
    yield put({
      type: types.GET_RESTAURANTS_SUCCESS,
      payload: response.data.message,
    });
    if(callback) callback(response.data.message)
  } catch (error) {
    yield put({type: types.GET_RESTAURANTS_FAILURE, payload: error});
  }
}

const _get_restaurants_watcher = takeEvery(
  types.GET_RESTAURANTS,
  _get_restaurants,
);

export default function* mainSaga() {
  yield all([_get_restaurants_watcher]);
}
