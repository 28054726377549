import React from 'react';
import { Typography, Container, Stack, Box, CircularProgress } from '@mui/material';
import { displayDateTime } from "./check"
import ErrorIcon from '@mui/icons-material/Error';

export const showStatusText = (val, owner) => {

    switch (val) {
        case "PENDING":
            return "Order recieved awating confirmation"
        case "ACCEPTED":
            {
                if (owner) return "Waiting for delivery request"
                else return "Order is being prepared"
            }
        case "DELIVERY_REQUESTED":
            return "Order sent to delivery person"
        case "DELIVERY_ACCEPTED":
            return "Delivery person is on the way"
        case "PICKED_UP":
            return "Order picked up by the delivery partner"
        case "DELIVERY_DELIVERED":
            return "Order delivered"
        case "CANCELLED":
            return "Order cancelled"
        case "NO_SHOW":
            return "Order not collected"
        default:
            return "Status unknown contact help";

    }

}

export const showStatusTextPartner = (val) => {

    switch (val) {
        case "PENDING":
            return "Please call the restaurant"
        case "ACCEPTED":
            "Order is being prepared"
        case "DELIVERY_REQUESTED":
            return "Order recieved please accept"
        case "DELIVERY_ACCEPTED":
            return "Order accepted by you , go for pickup"
        case "PICKED_UP":
            return "Order picked, deliver soon"
        case "DELIVERY_DELIVERED":
            return "Order delivered"
        case "CANCELLED":
            return "Order cancelled"
        case "NO_SHOW":
            return "Order not collected"
        default:
            return "Status unknown contact help";

    }

}

export const partnerStatus = ["DELIVERY_REQUESTED", "DELIVERY_ACCEPTED", "PICKED_UP"]
export const ownerStatus = ["PENDING", "ACCEPTED", "DELIVERY_REQUESTED", "DELIVERY_ACCEPTED"]

export const stepsVertical = (data) => {
    return [
        { title: `Order recieved : ${data?.createdAt && displayDateTime(data?.createdAt)}`, description: "order sent to restaurant awating confirmation", status: data?.status === "PENDING" },
        { title: `Order accepted : ${data?.logs?.ACCEPTED && displayDateTime(data?.logs?.ACCEPTED)}`, description: "order is being prepared", status: data?.status === "ACCEPTED" },
        { title: `Order packed : ${data?.logs?.DELIVERY_REQUESTED && displayDateTime(data?.logs?.DELIVERY_REQUESTED)}`, description: "order is packed request sent to delivery partner", status: data?.status === "DELIVERY_REQUESTED" },
        { title: `Order recieved by delivery partner : ${data?.logs?.DELIVERY_ACCEPTED && displayDateTime(data?.logs?.DELIVERY_ACCEPTED)}`, description: "Delivery person is on the way for pickup", status: data?.status === "DELIVERY_ACCEPTED" },
        { title: `Order Picked : ${data?.logs?.PICKED_UP && displayDateTime(data?.logs?.PICKED_UP)}`, description: "order is picked up by delivery person and is on the way", status: data?.status === "PICKED_UP" },
        { title: `Order Delivered : ${data?.logs?.DELIVERY_DELIVERED && displayDateTime(data?.logs?.DELIVERY_DELIVERED)}`, description: "order delivered", status: data?.status === "DELIVERY_DELIVERED" },
    ];
}

export const stepsHorizontal = (data) => {
    return [
        { title: "Order recieved", description: "order sent to restaurant awating confirmation", status: data?.status === "PENDING" },
        { title: "Order Picked", description: "order is picked up and is on the way", status: data?.status === "PICKED_UP" },
        { title: "Order Delivered", description: "order delivered", status: data?.status === "DELIVERY_DELIVERED" },
    ];
}

export const stepsCancelledHorizontal = (data) => {
    let arr = [{ title: `Order recieved `, description: "order sent to restaurant awating confirmation", status: data?.status === "PENDING" },]
    if (data?.status === "CANCELLED") arr?.push({
        title: `Order cancelled : ${data?.logs?.CANCELLED && displayDateTime(data?.logs?.CANCELLED)}`,
        description: `Order cancelled : ${data?.cancelledReason}`,
        status: data?.status === "CANCELLED"
    });
    else arr?.push({ title: `Customer no show `, description: "order not recieved by customer", status: data?.status === "NO_SHOW" });
    return arr;
}

export const warnIconMessage = (message) => {
    return (
        <Stack sx={{ alignItems: "center" }} >
            <ErrorIcon fontSize="large" />
            <Typography style={{ fontSize: '14px', fontWeight: 700, alignSelf: "center" }} variant="span" color="text.secondary">
                {message}
            </Typography>
        </Stack>
    )
}


export const filterRestaurantsArray = [
    { label: "Most popular", description: "Shows most popular", key: 'Popular' },
    { label: "Only available (Open)", description: "Shows only  which are available now", key: 'Available' },
    { label: "Sort by ascending (A to Z)", description: "Sort by name starting alphabetically by name", key: 'Ascending' },
    { label: "Sort by descending (Z to A)", description: "Sort by name starting reverse alphabetically by name", key: 'Descending' },
    // { label: "Pure vegetarian", description: "Shows only vegetarian", key: 'veg' },
    // { label: "Non vegetarian", description: "Shows only non vegetarian", key: 'non-veg' },
    // { label: "Chats", description: "Shows only chats", key: 'chats' },
    // { label: "Cafe", description: "Shows only cafe", key: 'cafe' },
]


export const filterDishesArray = [
    { label: "Only available (Open)", description: "Shows only available items that can be ordered now", key: 'Available' }
]

export const filterReviewsArray = [
    { label: "Most recent", description: "Shows reviews with most recent at the top", key: 'Descending' },
    { label: "Ascending ", description: "Shows reviews with most recent at the last", key: 'Ascending' },
    { label: "Show Only with comments", description: "Shows only reviews with comments", key: 'Comments' },
]

export const addItemsArray = [
    { label: "Description", type: "text", key: 'description', required: false, },
    { label: "Price full ", type: "number", key: 'priceFull', required: true, error: "Price is required" },
    { label: "Price half", type: "number", key: 'priceHalf', required: false, },
    { label: "Discount in rupees", type: "number", key: 'discount', required: false, },
]


export const loadProgress = () => (
    <CircularProgress
        size={24}
        sx={{
            color: "green",
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
        }}
    />
)


export const addFirmArray = (type) => {
    return [
        { label: `Name of the ${type} `, type: "text", key: 'name', required: true, error: `${name} Name is required`, },
        { label: "Description", type: "text", key: 'description', required: true, error: 'description is required' },
        { label: "Address", type: "text", key: 'address', required: true, error: 'Address is required', },
        { label: "Minimum order value ", type: "number", key: 'minOrder', required: true, error: 'Minimum order value required', },
        { label: "Estimated delivery time in minutes", type: "number", key: 'eta', required: true, error: 'Minutes required', },
        { label: "Id proof number of the owner", type: "number", key: 'idProof', required: true, error: 'Id proof number required', },
        // { label: "Taxes and charges", type: "number", key: 'tax', required: false, error: 'Taxes must be less than 100%', },
        { label: "Packing charges", type: "number", key: 'packingCharge', required: false, },
    ]
}


export const firmType = [
    { label: 'Pure Vegetarian', key: 'PURE-VEG' },
    { label: 'Non-vegetarian', key: 'NON-VEG' },
    { label: 'Vegetarian and Non-vegetarian', key: 'Veg & Non-Veg' },
    { label: 'Chats & Snacks bar', key: 'CHATS-SNACKS' },
    { label: 'Chats', key: 'CHATS' },
    { label: 'Snacks bar', key: 'SNACKS' },
    { label: 'Juice bar', key: 'JUICE' },
    { label: 'Ice Cream', key: 'ICE-CREAM' },
    { label: 'Desserts', key: 'DESSERTS' },
    { label: 'Groceries', key: 'Groceries' },
    { label: 'Others', key: 'OTHERS' },
];

export const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

export const addCouponArray = [
    { label: "Coupon Code", type: "text", key: 'code', required: true, error: "Code is required" },
    { label: "Discount percentage", type: "number", key: 'discount', required: true, error: "Discount percentage is required" },
    { label: "Description", type: "text", key: 'description', required: false, },
    { label: "Min Order value", type: "number", key: 'minorder', required: false, },
    { label: "Max Discount", type: "number", key: 'maxDiscount', required: false, },
    { label: "Start date", type: "date", key: 'start', required: true, error: "Please select date" },
    { label: "End date", type: "date", key: 'end', required: true, error: "Please select date" },
]

export const cancelReasonsList = (item) => {

    return [
        'The Items are not available',
        `${item} is closed`,
        'Delivery address is far',
        'Customer requested to cancel the order',
        'Multiple Orders placed ',
        'Delivery partner did not show up',
        'Delivery partner Rejected the order',
        'Delivery in malur town only',
    ]
}

export const addAddressArray = [
    { label: 'Full Name', type: "text", key: 'name', required: true, error: 'Name is required', },
    { label: 'Address line 1', type: "text", key: 'address1', required: true, error: 'Address line 1 is required', },
    { label: 'Address line 2', type: "text", key: 'address2', required: false, },
    { label: 'Phone number', type: "number", key: 'pno', required: true, error: 'Phone number is invalid', },
    { label: 'LandMark', type: "text", key: 'landMark', required: false },
];