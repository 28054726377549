import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import useDispatch from 'components/common/dispatchHook'
import { LOGIN_USER_SUCCESS } from 'containers/LoginPage/constants'
import {  DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom'

export default function LogoutMessage({ status, handleClose }) {
    const [dispatchAction, getStateData] = useDispatch();
    const router = useNavigate();

    function handleLogout() {
        localStorage.removeItem('x-access-token')
        dispatchAction({
            type: LOGIN_USER_SUCCESS,
            payload: {user: {}, version: ''},
          });
    }

    return (
        <div>
            <Dialog open={status} onClose={handleClose} fullWidth >
            <DialogTitle id="alert-dialog-title" textAlign={"center"} >
                    {`Are you sure you want to logout ?`}
                </DialogTitle>
                <DialogActions sx={{flexDirection: "row", justifyContent: "space-between"}} >
                    <Button onClick={handleLogout} size="large" color='error' >YES</Button>
                    <Button onClick={handleClose}  size="large" color='success' >NO</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}