import {
  GET_GROCERIES,
  GET_GROCERIES_FAILURE,
  GET_GROCERIES_SUCCESS,
  GET_GROCERY_MAINCAT,
  GET_GROCERY_MAINCAT_SUCCESS,
  GET_GROCERY_MAINCAT_FAILURE,
  GET_GROCERY_SUBCAT,
  GET_GROCERY_SUBCAT_FAILURE,
  GET_GROCERY_SUBCAT_SUCCESS,
  SAVE_MAIN_CATEGORY,
  SAVE_SUB_CATEGORY,
  UPDATE_GROCERY,
  UPDATE_GROCERY_FAILURE,
  UPDATE_GROCERY_SUCCESS,
  SAVE_SEARCH_TEXT,
  SAVE_GROCERY_OWNER,
  reducer,
} from './constants';

const initialState = {
  loading: false,
  groceriesList: [],
  groceriesMaincat: [],
  groceriesSubcat: [],
  maincategory: "",
  subcategory: "",
  searchText: "",
  saveGroceryOwner: false,

};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_GROCERIES:
    case GET_GROCERY_MAINCAT:
    case GET_GROCERY_SUBCAT:
    case UPDATE_GROCERY:
      return {
        ...state,
        loading: true,
      };
    case GET_GROCERIES_FAILURE:
    case GET_GROCERY_MAINCAT_FAILURE:
    case GET_GROCERY_SUBCAT_FAILURE:
    case UPDATE_GROCERY_FAILURE:
      return { ...state, loading: false };
    case UPDATE_GROCERY_SUCCESS:
      return { ...state, loading: false };
    case GET_GROCERIES_SUCCESS: {
      return {
        ...state,
        groceriesList: payload,
        loading: false,
      };
    }
    case GET_GROCERY_MAINCAT_SUCCESS: {
      return {
        ...state,
        groceriesMaincat: payload,
        loading: false,
      };
    }
    case GET_GROCERY_SUBCAT_SUCCESS: {
      return {
        ...state,
        groceriesSubcat: payload,
        loading: false,
      };
    }
    case SAVE_MAIN_CATEGORY:
      return { ...state, maincategory: payload };

    case SAVE_SUB_CATEGORY:
      return { ...state, subcategory: payload };

    case SAVE_SEARCH_TEXT:
      return { ...state, searchText: payload };
    case SAVE_GROCERY_OWNER:
      return { ...state, saveGroceryOwner: payload };
    default:
      return state;
  }
};

export const getGroceriesList = (state) => {
  return state[reducer].groceriesList;
};
export const getGroceriesMaincatList = (state) => {
  return state[reducer].groceriesMaincat;
};
export const getGroceriesSubcatList = (state) => {
  return state[reducer].groceriesSubcat;
};
export const getCurrentMaincategory = (state) => {
  return state[reducer].maincategory;
};
export const getSubcategory = (state) => {
  return state[reducer].subcategory;
};
export const getSearchText = (state) => {
  return state[reducer].searchText;
};
export const getGroceryOwnerStatus = (state) => {
  return state[reducer].saveGroceryOwner;
};
export const isLoading = (state) => {
  return state[reducer].loading;
};
export const isLoadingGroceries = (state) => {
  return state[reducer].loading;
}
export { reducer };
