import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import {showNotification} from 'containers/DashBoard/actions'


export default ()=>{
    const dispatch = useDispatch()
    const setMessage = (message) => {
        dispatch(showNotification(message))
    }
    return [setMessage]
}