import React from 'react';
import { Box, Grid, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { box, container } from './styles';
import Login from './login';

const List = () => (<ImageList sx={{
    width: "100%",
    height: '100%',
    // gridTemplateColumns: 'auto !important',
    position: 'relative',
    // top: '-2.1%',
    display: 'flex',
    justifyContent: 'center',
    opacity: '0.9'
}}>
    <ImageListItem key={"item.img"}>
        <img
            src={`/loader.gif`}
            alt={"loading..."}
            loading="lazy"
        />
        <ImageListItemBar
        />
    </ImageListItem>
</ImageList>)


export default function FormPropsTextFields() {

    return (<>
        <List />
        <div style={{ ...container }}>
            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item xs={11} md={10} sm={10} lg={10} xl={10}>
                    <Box
                        component="form"
                        sx={box}
                        noValidate
                        autoComplete="off"
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'center',
                            alignItems: 'center',
                            minHeight: '280px',
                            justifyContent: 'center',
                            background: 'rgb(0,0,0, 0.3)'
                        }}>
                            <Login />
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    </>
    );
}
