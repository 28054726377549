export const reducer = 'groceriesList';

export const GET_GROCERY_MAINCAT = reducer + '/GET_GROCERY_MAINCAT';
export const GET_GROCERY_MAINCAT_SUCCESS = reducer + '/GET_GROCERY_MAINCAT_SUCCESS';
export const GET_GROCERY_MAINCAT_FAILURE = reducer + '/GET_GROCERY_MAINCAT_FAILURE';

export const GET_GROCERY_SUBCAT = reducer + '/GET_GROCERY_SUBCAT';
export const GET_GROCERY_SUBCAT_SUCCESS = reducer + '/GET_GROCERY_SUBCAT_SUCCESS';
export const GET_GROCERY_SUBCAT_FAILURE = reducer + '/GET_GROCERY_SUBCAT_FAILURE';

export const GET_GROCERIES = reducer + '/GET_GROCERIES';
export const GET_GROCERIES_SUCCESS = reducer + '/GET_GROCERIES_SUCCESS';
export const GET_GROCERIES_FAILURE = reducer + '/GET_GROCERIES_FAILURE';

export const UPDATE_GROCERY = reducer + '/UPDATE_GROCERY';
export const UPDATE_GROCERY_SUCCESS = reducer + '/UPDATE_GROCERY_SUCCESS';
export const UPDATE_GROCERY_FAILURE = reducer + '/UPDATE_GROCERY_FAILURE';

export const SAVE_MAIN_CATEGORY = reducer + '/SAVE_MAIN_CATEGORY';
export const SAVE_SEARCH_TEXT = reducer + '/SAVE_SEARCH_TEXT';
export const SAVE_SUB_CATEGORY = reducer + '/SAVE_SUB_CATEGORY';
export const SAVE_GROCERY_OWNER = reducer + '/SAVE_GROCERY_OWNER';

