import * as React from 'react';
import moment from 'moment'
import { Typography } from '@mui/material';

function timeToMins(time) {
    if (!time) return
    var b = time?.split(':');
    var c = b[2]?.split(' ');
    var d = b[0];
    // if (c[1] === 'PM' || c[1] === 'pm') {
    //   d = +d + 12;
    // }
    if (c[1] === 'PM' || c[1] === 'pm') {
        if (d === '12') {
            d = 12;
        } else {
            d = +d + 12;
        }
    }
    if ((c[1] === 'AM' || c[1] === 'am') && d === '12') {
        d = 0;
    }
    return d * 60 + +b[1];
}


export const checkTimings = (availableTimings) => {
    if (!availableTimings) return
    const format = 'HH:mm'
    const times = availableTimings?.split('-')
    // var time = moment() gives you current time. no format required.
    const time = moment(),
        beforeTime = moment(moment(times[0], ['h:mm A']).format(format), format),
        afterTime = moment(moment(times[1], ['h:mm A']).format(format), format);
    return time.isBetween(beforeTime, afterTime)
    // console.log(availableTimings)
    // let status = false;
    // const timeOpen = timeToMins(
    //     availableTimings
    //         ?.substring(0, 8)
    //         ?.replace(/ /g, ':00 ')
    //         ?.toUpperCase(),
    // );
    // const timeclose = timeToMins(
    //     availableTimings
    //         ?.substring(9)
    //         ?.replace(/ /g, ':00 ')
    //         ?.toUpperCase(),
    // );
    // var timePresent = timeToMins(new Date().toLocaleTimeString());
    // if (new Date().toLocaleTimeString()?.length === 10) {
    //     timePresent = timeToMins('0' + new Date().toLocaleTimeString());
    // }

    // if (
    //     timeOpen < timePresent &&
    //     timePresent < timeclose
    // ) {
    //     status = true;
    // } else {
    //     status = false;
    // }
    // return status;
}


export const openstatus = (res) => {
    let status = false;
    if (!res) return false;
    // const timeOpen = timeToMins(
    //     res?.availableTimings
    //         ?.substring(0, 8)
    //         ?.replace(/ /g, ':00 ')
    //         ?.toUpperCase(),
    // );
    // const timeclose = timeToMins(
    //     res?.availableTimings
    //         ?.substring(9)
    //         ?.replace(/ /g, ':00 ')
    //         ?.toUpperCase(),
    // );
    // var timePresent = timeToMins(new Date().toLocaleTimeString());
    // if (new Date().toLocaleTimeString()?.length === 10) {
    //     timePresent = timeToMins('0' + new Date().toLocaleTimeString());
    // }
    const today = new Date().toDateString()?.split(' ')[0];
    const OpenDays = res?.availableDays?.split(',');
    const Days = OpenDays?.map((l) => {
        return l.slice(0, 3);
    });
    if (
        Days?.includes(today) &&
        checkTimings(res?.availableTimings)
        // timeOpen < timePresent &&
        // timePresent < timeclose
    ) {
        status = true;
    } else {
        status = false;
    }
    return status;
}

const message = (open, delivery, deliveryService, res) => {
    let mes = `Usually delivered in ${res?.eta} - ${res?.eta + 10} min`;
    if (open) {
        if (!delivery) mes = `${res?.type} is not accepting orders`
    }
    else mes = `${res?.type} is closed`
    return mes
}


export const checkRestaurantStatus = (res) => {
    let open = openstatus(res);
    let delivery = res?.isDelivery;
    let deliveryService = true;
    let status = open && delivery && deliveryService;
    return { status: status, message: message(open, delivery, deliveryService, res) }
}


export const checkday = (date) => {
    let today = false;
    if (new Date(date)?.toDateString() === new Date()?.toDateString()) today = true;
    return today
}

export const text = (tex, color = "black", size = 10, textAlign = "left", isPno = false) => (
    <Typography gutterBottom
        variant="span"
        fontSize={size}
        color={color}
        textAlign={textAlign}
        sx={{
            fontWeight: 700,
            margin: 0,
        }}
        component="div">
        {!isPno? tex : 
        <a href={`tel:+91${tex}`}>{tex}</a>}
    </Typography>
)

export const displayDateTime = (date, mode) => {
    let res = new Date(date)?.toLocaleString('en-GB', { hour12: true, dateStyle: 'short', timeStyle: 'short' })
    if (mode === "time") res = new Date(date)?.toLocaleString('en-GB', { hour12: true, timeStyle: 'short' })
    else if (mode === "date") res = new Date(date)?.toLocaleString('en-GB', { hour12: true, dateStyle: 'short' })
    return res
}


export const sortArray = (items, value, method) => {
    // send method as true for ascending (A to Z) and false for descending (Z to A)
    let data = items?.sort((a, b) => {
        const nameA = a[value];
        const nameB = b[value];
        if (nameA < nameB) {
            return method === "Ascending" ? -1 : 1;
        }
        if (nameA > nameB) {
            return method === "Ascending" ? 1 : -1;
        }
        // names must be equal
        return 0;
    });
    return data
}


export const convertTo12Hour = (oldFormatTime) => {
    var oldFormatTimeArray = oldFormatTime?.toUpperCase()?.split(":");
    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    var AMPM = HH >= 12 ? "PM" : "AM";
    var hours;
    if (HH == 0) {
        hours = HH + 12;
    } else if (HH > 12) {
        hours = HH - 12;
    } else {
        hours = HH;
    }
    if (hours < 10) {
        hours = `0${hours}`
    }
    var newFormatTime = hours + ":" + min + " " + AMPM;
    return newFormatTime;
}

export const convertTime12to24 = (time12h) => {
    // const [time, modifier] = time12h?.toUpperCase()?.replace(' ','');
    const a = time12h?.toUpperCase()?.replace(' ', '');
    const time = a?.substr(0, 5);
    const modifier = a?.substr(-2);
    let [hours, minutes] = time?.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier?.toUpperCase() === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
}