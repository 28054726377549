import {
  reducer,
  ADD_ORDER,
  ADD_ORDER_FAILURE,
  ADD_ORDER_SUCCESS,
  ADD_NEW_ADDRESS,
  ADD_NEW_ADDRESS_FAILURE,
  ADD_NEW_ADDRESS_SUCCESS,
  GET_DELIVERY_ADDRESS,
  GET_DELIVERY_ADDRESS_FAILURE,
  GET_DELIVERY_ADDRESS_SUCCESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_SUCCESS,
  SAVE_CURRENT_DELIVERY_ADDRESS,
  SAVE_SELECTED_COUPON,
  GET_COUPONS,
  GET_COUPONS_FAILURE,
  GET_COUPONS_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  dishesList: [],
  dishCategoryList: [],
  deliveryAddressList: [],
  currentaddress: {},
  coupon: {},
  couponsList:[],
};
4;

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_ORDER_SUCCESS:
    case ADD_NEW_ADDRESS_SUCCESS:
    case UPDATE_ADDRESS_SUCCESS:
      return {...state, loading: false};

    case ADD_ORDER_FAILURE:
    case ADD_NEW_ADDRESS_FAILURE:
    case UPDATE_ADDRESS_FAILURE:
    case GET_DELIVERY_ADDRESS_FAILURE:
    case GET_COUPONS_FAILURE:
      return {...state, loading: false};

    case ADD_ORDER:
    case ADD_NEW_ADDRESS:
    case UPDATE_ADDRESS:
    case GET_DELIVERY_ADDRESS:
    case GET_COUPONS:
      return {
        ...state,
        loading: true,
      };
    case GET_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        deliveryAddressList: payload,
        loading: false,
      };
    }
    case GET_COUPONS_SUCCESS: {
      return {
        ...state,
        couponsList: payload,
        loading: false,
      };
    }

    case SAVE_CURRENT_DELIVERY_ADDRESS:
      return {...state, currentaddress: payload};
      case SAVE_SELECTED_COUPON:
        return {...state, coupon: payload};

    default:
      return state;
  }
};

export const getDeliveryAddressList = (state) => {
  return state[reducer].deliveryAddressList;
};
export const getCurrentDeliveryAddress = (state) => {
  return state[reducer].currentaddress;
};
export const getSelectedCoupon = (state) => {
  return state[reducer].coupon;
};
export const getCouponsList = (state) => {
  return state[reducer].couponsList;
};
export const isLoading = (state) => {
  return state[reducer].loading;
};
export {reducer};
