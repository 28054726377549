export const reducer = 'Login';

export const GET_USER = reducer + '/GET_USER';
export const GET_USER_SUCCESS = reducer + '/GET_USER_SUCCESS';
export const GET_USER_FAILURE = reducer + '/GET_USER_FAILURE';

export const LOGIN_USER = reducer + '/LOGIN_USER';
export const LOGIN_USER_SUCCESS = reducer + '/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = reducer + '/LOGIN_USER_FAILURE';

export const CHANGE_PASSWORD = reducer + '/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = reducer + '/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = reducer + '/CHANGE_PASSWORD_FAILURE';

export const RESTAURANT_ADDED = reducer + '/RESTAURANT_ADDED';
export const RESTAURANT_UPDATED = reducer + '/RESTAURANT_UPDATED';

export const SAVE_DEVICE_TOKEN = reducer + '/SAVE_DEVICE_TOKEN';
export const SAVE_NOTIFICATION = reducer + '/SAVE_NOTIFICATION';
export const SAVE_PNO = reducer + '/SAVE_PNO';
export const TRIGGER_FLAGS = reducer + '/TRIGGER_FLAGS';
export const TRIGGER_NATIVE_BACK = reducer + '/TRIGGER_NATIVE_BACK';

