import {GET_RESTAURANTS, SAVE_CURRENT_RESTAURANT,TGRIGGER_BACKBTN} from './constants';

export const getRestaurants = (payload) => ({
  type: GET_RESTAURANTS,
  payload,
});

export const saveCurrentRestaurant = (payload) => ({
  type: SAVE_CURRENT_RESTAURANT,
  payload,
});
export const saveTriggerBackbtn = (payload) => ({
  type: TGRIGGER_BACKBTN,
  payload,
});