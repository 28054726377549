import React from 'react';
import { Stack, Box, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation } from 'react-router-dom'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import RestaurantCard from './restaurantCard'
import { getCurrentRestaurant } from 'containers/Restaurants/RestaurantsList/reducer';
import { getCurrentTheme } from '../../global/ThemeReducer';
import useDispatch from 'components/common/dispatchHook'
import ConfirmModal from 'components/common/confirm-modal';
import { triggerAddItem } from 'containers/Restaurants/RestaurantDishes/actions';
import { getTriggeredItem } from 'containers/Restaurants/RestaurantDishes/reducer';
import { getNativeBackPress } from 'containers/LoginPage/reducer';
import { handleNativeBackPress } from 'containers/LoginPage/actions';

export default function Header({ title, isDetailPage, data, disableBack, hideHome }) {
    const [dispatchAction, getStateData] = useDispatch();
    const currentRestaurant = getStateData(getCurrentRestaurant);
    const { palette } = getStateData(getCurrentTheme)
    const currentOrder = getStateData(getTriggeredItem);
    const router = useNavigate();
    const locatin = useLocation();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const backPress = getStateData(getNativeBackPress)

    React.useEffect(() => {
        // alert(backPress)
        if (backPress) {
            handleBackArrow();
            dispatchAction(handleNativeBackPress(false));
        }
    }, [backPress])

    function handleCheck(val) {
        if (
            currentOrder?.count > 0 &&
            currentOrder?.items &&
            Object.keys(currentOrder?.items).length > 0 &&
            locatin?.pathname?.indexOf('thanks') === -1
        ) {
            setShowConfirm(true);
        }
        else router(val);
    }

    function handleBackArrow() {
        if (isDetailPage) handleCheck(-1);
        else router(-1)
    }

    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: palette.mode === 'light' ? '#F78F35' : '#671a1a',
        }}>
            {!disableBack && <KeyboardArrowLeftOutlinedIcon style={{ position: 'absolute', top: 10, left: 5 }} onClick={() => handleBackArrow()} fontSize='large' />}
            {isDetailPage ? <Box color="grey" sx={{ width: '95%', marginLeft: '3%', marginTop: '12%' }}>
                <RestaurantCard data={currentRestaurant && currentRestaurant} isDetailPage router={router} />
            </Box> : <Typography style={{ marginTop: 13, marginLeft: 50 }} gutterBottom variant="h6" component="div">
                {title}
            </Typography>}
            {!hideHome && <HomeIcon onClick={() => handleCheck('/dashboard')} style={{ position: 'absolute', right: '3%' }} fontSize="large" />}
            <ConfirmModal
                message="Items in the cart will be lost if you go back"
                title="Are you sure ?"
                showConfirm={showConfirm}
                handleOk={() => {
                    dispatchAction(triggerAddItem({}));
                    setShowConfirm(false);
                    if (hideHome) router(-1);
                    else router('/dashboard')
                }}
                handleCancel={() => {
                    setShowConfirm(false);
                    // router(+1);
                }}
            />
        </Stack>
    );
}