export const reducer = 'CartPage';

export const UPDATE_ADDRESS = reducer + '/UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = reducer + '/UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = reducer + '/UPDATE_ADDRESS_FAILURE';

export const ADD_ORDER = reducer + '/ADD_ORDER';
export const ADD_ORDER_SUCCESS = reducer + '/ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = reducer + '/ADD_ORDER_FAILURE';

export const ADD_NEW_ADDRESS = reducer + '/ADD_NEW_ADDRESS';
export const ADD_NEW_ADDRESS_SUCCESS = reducer + '/ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_FAILURE = reducer + '/ADD_NEW_ADDRESS_FAILURE';

export const GET_DELIVERY_ADDRESS = reducer + '/GET_DELIVERY_ADDRESS';
export const GET_DELIVERY_ADDRESS_SUCCESS = reducer + '/GET_DELIVERY_ADDRESS_SUCCESS';
export const GET_DELIVERY_ADDRESS_FAILURE = reducer + '/GET_DELIVERY_ADDRESS_FAILURE';

export const GET_COUPONS = reducer + '/GET_COUPONS';
export const GET_COUPONS_SUCCESS = reducer + '/GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = reducer + '/GET_COUPONS_FAILURE';

export const SAVE_CURRENT_DELIVERY_ADDRESS = reducer + '/SAVE_CURRENT_DELIVERY_ADDRESS';
export const SAVE_SELECTED_COUPON = reducer + '/SAVE_SELECTED_COUPON';
