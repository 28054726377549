import {
  GET_DISHES,
  GET_DISHES_CATEGORY,
  UPDATE_DISH,
  SAVE_CURRENT_ORDER,
  TRIGGER_ADD_ITEM,
  TRIGGER_VIEW_CART,
  UPDATE_DISH_ARRAY,
  SAVE_CURRENT_ITEM
} from './constants';

export const updateDish = (payload) => ({
  type: UPDATE_DISH,
  payload,
});

export const getDishes = (payload) => ({
  type: GET_DISHES,
  payload,
});

export const getDishesCategory = (payload) => ({
  type: GET_DISHES_CATEGORY,
  payload,
});

export const saveOrderInfo = (payload) => ({
  type: SAVE_CURRENT_ORDER,
  payload,
});

export const triggerAddItem = (payload) => ({
  type: TRIGGER_ADD_ITEM,
  payload,
});

export const triggerViewCart = (payload) => ({
  type: TRIGGER_VIEW_CART,
  payload,
});
export const updateDishArray = (payload) => ({
  type: UPDATE_DISH_ARRAY,
  payload,
});
export const saveCurrentItem = (payload) => ({
  type: SAVE_CURRENT_ITEM,
  payload,
});