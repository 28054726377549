import React from 'react';
import {
  Box,
  Toolbar,
} from '@mui/material';
import Header from './Header';
import BottomNavigationComponent from './bottomNavigator';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import Fab from '@mui/material/Fab';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 400,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 100, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};



export default (WrapperComponent, title, Scroll = true) => {

  return (props) => {
    return (
      <Box >
        <Header title={title} />
        <Toolbar id="back-to-top-anchor" sx={{ minHeight: 5 }} />
        <WrapperComponent {...props} id='#back-to-top-anchor' />
        <Box sx={{ marginBottom: "40%" }} />
        <BottomNavigationComponent />
        {Scroll && <ScrollTop {...props}>
          <Fab size="large" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>}
      </Box >
    );
  };
};
